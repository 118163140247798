import {ReactElement, Suspense} from "react";
import Loading from "../components/Loading";

/**
 * @Composable
 * Обёртка suspense для <Loading/>
 * @param element
 */
function withLoadable(element: ReactElement) : ReactElement {
    return (
        <Suspense fallback={<Loading/>}>
            {element}
        </Suspense>
    );
}

export default withLoadable;