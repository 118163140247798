import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {calculatorSlice} from "../calculator/calculatorSlice";


interface IPopupSliceState {
    openedPopupId?: string,
}

const initialState: IPopupSliceState = {
    openedPopupId: null,
}

export const popupSlice = createSlice({
    name: 'popup',
    initialState,
    reducers: {
        openPopupById: (state, action: PayloadAction<string>) => {
            state.openedPopupId = action.payload;
        },
        closePopup: (state) => {
            state.openedPopupId = null;
        },
    }
})

export const { openPopupById, closePopup } = popupSlice.actions
export default popupSlice.reducer