import styled from "styled-components";

/**
 * @Composable
 * Поле ввода
 * @extends input
 */
const ScInput = styled.input`
    border: 1px solid ${props => props.theme.colors.secondary}aa;  /*'aa' - is alpha channel of hex*/
    border-radius: ${props => props.theme.rounded.normal};

    min-width: 190px;
    padding: 1rem;

    outline: none;
    width: auto;

    font-size: ${props =>props.theme.typography.fontSizes.normal};

    &::placeholder {
        color: ${props => props.theme.colors.tertiaryVariant};
    }
    
    &[aria-invalid="true"],
    &._invalid {
        border-color: ${props => props.theme.colors.error};
        color: ${props => props.theme.colors.error};
    }
    
    &.hide-arrows {
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        &[type=number] {
            -moz-appearance: textfield;
        }
    }
`

export default ScInput;