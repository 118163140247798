import {getFakeStreet} from "./streets";
import {getFakeShortPerson} from "./persons";
import {ILabor, LaborStatus} from "../model/labor";
import {RoomType, ServiceType} from "../calculator";

const laborStatuses = [
    LaborStatus.NEW,
    LaborStatus.WORKING,
    LaborStatus.CLEANER_CONFIRMED,
    LaborStatus.FROZEN,
    LaborStatus.CLIENT_CONFIRMED
]

const serviceTypes = [
    ServiceType.General,
    ServiceType.Build,
    ServiceType.Support
]

export const getFakeLaborId = () : string => {
    return Math.floor(Math.random() * 1000000).toString();
}

export const getFakeLabor = () : ILabor => {
    const fakeId = getFakeLaborId()

    return {
        id: fakeId,

        room: RoomType.Apartment,
        square: Math.floor(Math.random() * 70 + 30),
        service: serviceTypes[Math.floor(Math.random() * serviceTypes.length)],
        subservice: "default",
        dateTimeEpoch: new Date(2024, 9, Math.random() * 10 + 10, Math.random() * 8 + 8, Math.random() * 60).getTime(),
        additionals: [],
        promoValue: fakeId,
        promoQuantifier: 1,
        usedPoints: 0,

        address: getFakeStreet(),
        personName: getFakeShortPerson(),

        status: laborStatuses[Math.floor(Math.random() * laborStatuses.length)]
    }
}