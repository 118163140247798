import {createSlice} from '@reduxjs/toolkit'


interface IWalletSliceState {
    /**
     * Состояние остатка баланса пользователя в рублях
     */
    balanceValue: number,
    /**
     * Количество баллов на счету у пользователя
     */
    pointsValue: number,
}

const initialState: IWalletSliceState = {
    balanceValue: 986,
    pointsValue: 500,
}

export const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {}
})

export default walletSlice.reducer