import { createSlice } from '@reduxjs/toolkit'

type LoadingState = {
    hint: string,
    value: boolean
}

const initialState: LoadingState = {
    hint: "Загрузка...",
    value: false
}

export const loadingSlice = createSlice({
    name: 'field',
    initialState,
    reducers: {
        setHint: (state, action) => {
            state.hint = action.payload;
        },
        showLoading: (state) => {
            state.value = true;
        },
        shoveLoading: (state) => {
            state.value = false;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setHint } = loadingSlice.actions
export default loadingSlice.reducer