import * as React from "react";
import { CurrentContext } from "./LaborContext";


interface LaborProviderProps {
    laborId: string,
    children: React.ReactNode
}

export const LaborContextProvider = (props: LaborProviderProps) => {
    return (
        <CurrentContext.Provider value={{ laborId: props.laborId }}>
            {props.children}
        </CurrentContext.Provider>
    )
};

export default LaborContextProvider