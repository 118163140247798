import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {THEME} from "./utils/globals";
import {ThemeProvider} from "styled-components";
import store from "./redux/store";
import {Provider as ReduxProvider} from "react-redux";
import AuthContextProvider from "./api/authcontext/AuthContextProvider";
import ReactDOM from 'react-dom/client';
import React from 'react';
import {QueryClient, QueryClientProvider} from "react-query";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient()

root.render(
    <ThemeProvider theme={THEME}>
        <ReduxProvider store={store}>
            <AuthContextProvider>
                <QueryClientProvider client={queryClient}>
                    {/*<React.StrictMode>*/}
                    <App />
                    {/*</React.StrictMode>*/}
                </QueryClientProvider>
            </AuthContextProvider>
        </ReduxProvider>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
