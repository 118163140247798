import * as React from "react";
import {User} from "./user";

type AuthContextValue = {
    user?: User,
    setUser: (user: User) => void,
    ensureUser: () => Promise<boolean>,
}

export const CurrentContext = React.createContext<AuthContextValue>(null)

export function useAuthContext() {
    const context = React.useContext(CurrentContext);
    if (!context) {
        throw new Error(`#701 Не обнаружен родительский AuthContextProvider`);
    }

    return context;
}

export default useAuthContext;