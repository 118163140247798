import styled from "styled-components";
import * as React from "react";

const boxSizePx = 13;

/**
 * Компонент input.radio для выбора значения
 * @param props
 * @constructor
 */
export function ClassicRadioButton(props: {
    value: string,
    checked: boolean,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    text : string
}) {
    const { text, ...other } = props;

    return (
        <ScRadioItem>
            <ScRadioInput
                {...other}
            />
            <ScRadioButtonLabel />
            <div className={'spacer'}></div>
            <span>{text}</span>
        </ScRadioItem>
    );
}

const ScRadioButtonLabel = styled.label`
    position: absolute;
    left: 4px;
    width: ${boxSizePx}px;
    height: ${boxSizePx}px;
    border-radius: 4px;
    background: white;
    border: 1px solid ${props => props.theme.colors.secondary};
`;

const ScRadioInput = styled.input.attrs(props => ({
    type: 'radio'
}))`
    opacity: 0;
    cursor: pointer;
    width: ${boxSizePx}px;
    height: ${boxSizePx}px;
    z-index: 1;

    &:checked + ${ScRadioButtonLabel} {
        background-image: url('data:image/svg+xml;utf8,<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="11" height="11" rx="4" fill="%232D4149"/></svg>');
        background-position: center;
        background-repeat: no-repeat;
    }
`;

const ScRadioItem = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    
    > *:not(${ScRadioInput}):not(${ScRadioButtonLabel}) {
        
    }
    
    .spacer {
        width: 7px;
    }
`;