import {preferDate, RoomType, ServiceType} from "../calculator";
import {getFakeStreet} from "../fakedata/streets";
import {getFakeShortPerson} from "../fakedata/persons";

export const LABOR_NEW_ID = "new";

/**
 * Статус заказа
 */
export enum LaborStatus {
    /**
     * Заказ создан
     */
    NEW,
    /**
     * Клинер начал работы над заказом
     */
    WORKING,
    /**
     * Клинер закончил работы над заказом
     */
    CLEANER_CONFIRMED,
    /**
     * Клиент подтвердил завершение работы над заказом
     */
    CLIENT_CONFIRMED,
    /**
     * Заказ завершён
     */
    FINISHED,
    /**
     * Заказ заморожен
     */
    FROZEN
}

/**
 * Интерфейс результата калькуляции
 */
export interface ICalculationResult {
    /**
     * Айди
     */
    id: string
    /**
     * Сумма основной части калькулятора
     */
    primarySum: number,
    /**
     * Сумма дополнительных услуг
     */
    additionalsSum: number,
    /**
     * Базовая сумма
     */
    mainSum: number,

    /**
     * Множитель промокода для базовой суммы
     */
    promoQuantifier: number,
    /**
     * Скалькулированная скидка промокода для базовой суммы
     */
    promoDiscountValue: number,

    /**
     * Количество бонусных баллов
     */
    pointsDiscountValue: number,
    /**
     * Максимальное доступное количество бонусных баллов для траты в этом заказе
     */
    maxAvailablePointsValue: number,
    /**
     * Итоговая сумма
     */
    totalSum: number
}

export const CalculationResultDefaults : ICalculationResult = {
    id: LABOR_NEW_ID,
    additionalsSum: 0,
    mainSum: 0,
    maxAvailablePointsValue : 0,
    pointsDiscountValue : 0,
    primarySum : 0,
    promoDiscountValue : 0,
    promoQuantifier : 0,
    totalSum : 0
}

export interface ILabor {
    id: string,
    room: RoomType,
    square: number,
    service: ServiceType,
    subservice: string,
    dateTimeEpoch: number,
    additionals: string[],
    promoValue: string,
    promoQuantifier: number,
    usedPoints: number,

    address: string,
    personName: string,
    status: LaborStatus
}

export const LaborDefaults : ILabor = {
    id: LABOR_NEW_ID,
    room: RoomType.Apartment,
    square: 30,
    service: ServiceType.General,
    subservice: "default",
    dateTimeEpoch: preferDate(new Date()).getTime(),
    additionals: [],
    promoValue: "",
    promoQuantifier: 1,
    usedPoints: 0,

    address: getFakeStreet(),
    personName: getFakeShortPerson(),
    status: LaborStatus.NEW
}