import {RootState} from "../../store";
import {createDraftSafeSelectorCreator, createSelector, weakMapMemoize} from "@reduxjs/toolkit";
import {calculationResultAdapter, laborAdapter} from "./calculatorSlice";
import {selectWalletReducer} from "../wallet/walletSelectors";
import {LABOR_NEW_ID} from "../../../api/model/labor";

const createWeakMapDraftSafeSelector =
    createDraftSafeSelectorCreator(weakMapMemoize)

const selectCalculatorReducer = (state: RootState) => state.calculatorReducer;
const selectCalculatorLabors = (state: RootState) => state.calculatorReducer.labors;
const selectCalculationResults = (state: RootState) => state.calculatorReducer.calculationResults;

const {
    selectById: laborSelectById,
    selectAll: laborSelectAll,
} = laborAdapter.getSelectors(undefined, {
    createSelector: createWeakMapDraftSafeSelector
})

const {
    selectById: calculationResultSelectById,
} = calculationResultAdapter.getSelectors(undefined, {
    createSelector: createWeakMapDraftSafeSelector
})


/**
 * @Selector
 * Выбрать максимальное количество доступных для использования баллов с учётом:
 * - выбранной конфигурации заказа
 * - доступного у пользователя в кошельке баллов
 */
export const selectCalculatorMaxAvailablePointsValue = (id: string) => createSelector(
    [selectCalculationResults, selectWalletReducer],
    (calculationResults, walletReducer) => Math.min(
        calculationResultSelectById(calculationResults, id).maxAvailablePointsValue,
        walletReducer.pointsValue
    )
)

/**
 * @Selector
 * Выбрать результат калькуляций.
 * @remarks Более глубокая выборка не даст оптимизации, так как объект обновляется целиком (поля зависимы друг от друга)
 */
export const selectCalculationResult = (id: string) => createSelector(
    [selectCalculationResults],
    (calculationResults) => calculationResultSelectById(calculationResults, id)
)


/**
 * @Selector
 * Выбрать заказ по айди
 */
export const selectLaborById = (id: string) => createSelector(
    [selectCalculatorLabors],
    (calculatorLabors) => laborSelectById(calculatorLabors, id)
)


/**
 * @Selector
 * Выбрать все заказы
 */
export const selectLabors = createSelector(
    [selectCalculatorLabors],
    (calculatorLabors) => laborSelectAll(calculatorLabors).filter((labor) => labor.id !== LABOR_NEW_ID)
)

/**
 * @Selector
 * Получить айди редактируемых/просматриваемых в данный момент заказов во вкладках
 */
export const selectOpenedLaborIds = createSelector(
    [(state: RootState) => state.calculatorReducer.openedLaborIds],
    (openedLaborIds) => openedLaborIds
)