export enum Role {
    INDIVIDUAL = 'individual',
    CORPORATE = 'corporate',
    CLEANER = 'cleaner',
    ADMIN = 'admin'
}

export class User {
    public role?: Role

    constructor(_role: Role = null) {
        this.role = _role;
    }

    public isGuest(): boolean {
        return this.role === null;
    }

    public isAdmin(): boolean {
        return this.role === Role.ADMIN;
    }

    public isClient(): boolean {
        return this.role === Role.INDIVIDUAL || this.role === Role.CORPORATE;
    }

    public isCleaner(): boolean {
        return this.role === Role.CLEANER;
    }
}