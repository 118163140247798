import { AppDispatch } from '../../store';
import { flagSlice } from './flagSlice';

export const flagFalse = () => (dispatch: AppDispatch) => {
  dispatch(flagSlice.actions.flagFalse(false));
};

export const spoilerOneTrue = () => (dispatch: AppDispatch) => {
  dispatch(flagSlice.actions.flagSpoilerOne(true));
  dispatch(flagSlice.actions.flagSpoilerTwo(false));
  dispatch(flagSlice.actions.flagSpoilerThree(false));
  dispatch(flagSlice.actions.flagSpoilerFour(false));
  dispatch(flagSlice.actions.flagSpoilerFive(false));
};

export const spoilerTwoTrue = () => (dispatch: AppDispatch) => {
  dispatch(flagSlice.actions.flagSpoilerOne(false));
  dispatch(flagSlice.actions.flagSpoilerTwo(true));
  dispatch(flagSlice.actions.flagSpoilerThree(false));
  dispatch(flagSlice.actions.flagSpoilerFour(false));
  dispatch(flagSlice.actions.flagSpoilerFive(false));
};

export const spoilerThreeTrue = () => (dispatch: AppDispatch) => {
  dispatch(flagSlice.actions.flagSpoilerOne(false));
  dispatch(flagSlice.actions.flagSpoilerTwo(false));
  dispatch(flagSlice.actions.flagSpoilerThree(true));
  dispatch(flagSlice.actions.flagSpoilerFour(false));
  dispatch(flagSlice.actions.flagSpoilerFive(false));
};

export const spoilerFourTrue = () => (dispatch: AppDispatch) => {
  dispatch(flagSlice.actions.flagSpoilerOne(false));
  dispatch(flagSlice.actions.flagSpoilerTwo(false));
  dispatch(flagSlice.actions.flagSpoilerThree(false));
  dispatch(flagSlice.actions.flagSpoilerFour(true));
  dispatch(flagSlice.actions.flagSpoilerFive(false));
};

export const spoilerFiveTrue = () => (dispatch: AppDispatch) => {
  dispatch(flagSlice.actions.flagSpoilerOne(false));
  dispatch(flagSlice.actions.flagSpoilerTwo(false));
  dispatch(flagSlice.actions.flagSpoilerThree(false));
  dispatch(flagSlice.actions.flagSpoilerFour(false));
  dispatch(flagSlice.actions.flagSpoilerFive(true));
};

export const spoilersReset = () => (dispatch: AppDispatch) => {
  dispatch(flagSlice.actions.flagSpoilerOne(false));
  dispatch(flagSlice.actions.flagSpoilerTwo(false));
  dispatch(flagSlice.actions.flagSpoilerThree(false));
  dispatch(flagSlice.actions.flagSpoilerFour(false));
  dispatch(flagSlice.actions.flagSpoilerFive(false));
};

export const flagReset = () => (dispatch: AppDispatch) => {
  dispatch(flagSlice.actions.flagReset());
};
