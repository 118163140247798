import React from "react";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {
    updateLabor,
} from "../../../redux/reducers/calculator/calculatorSlice";
import {RootState} from "../../../redux/store";
import ScInput from "../../../components/styled/ScInput";
import {ScOutlinedIconButton} from "../../../components/styled/ScButton";
import CheckmarkIcon from "../../../components/icons/CheckmarkIcon";
import {useQuery} from "react-query";
import styled from "styled-components";
import {selectLaborById} from "../../../redux/reducers/calculator/calculatorSelectors";
import useLaborContext from "../../../api/laborcontext/LaborContext";


/**
 * @Composable
 * Виджет промокода калькулятора
 * @constructor
 */
function CalculatorPromoWidget() : React.JSX.Element {
    const dispatch = useAppDispatch();
    const { laborId } = useLaborContext();
    const labor = useAppSelector(selectLaborById(laborId));


    const requestPromo = async (value: string) : Promise<void> => {
        await new Promise(r => setTimeout(r, 1500));

        const match = value.match(/^SUMMER(\d\d)$/);

        const calculatedPercentage = match ? Number(match[1]) : 0;
        dispatch(updateLabor({ id: laborId,  promoQuantifier: (100 - calculatedPercentage) / 100 }));
    }

    const {
        refetch: promoRefetch,
        isFetching: promoIsFetching,
        isFetched: promoIsFetched
    } = useQuery(
        "promo",
        () => requestPromo(labor.promoValue),
        {
            enabled: false,
            refetchOnWindowFocus: false
        }
    )

    return (
        <ScCalculatorPromoWidget>
            <div>
                <ScInput value={labor.promoValue} onChange={(e) => {
                    dispatch(updateLabor({ id: laborId, promoValue: e.target.value }));
                }}/>
                <ScOutlinedIconButton onClick={() => promoRefetch()}><CheckmarkIcon/></ScOutlinedIconButton>
            </div>

            <span className="calc__row--promo_comment">{
                promoIsFetching ? 'Загрузка...'
                    : (labor.promoQuantifier !== 1 ? `Применён промокод на ${100 - labor.promoQuantifier * 100}% `
                        : (promoIsFetched ? 'Промокод не принят' : '\u00A0'))}
            </span>
        </ScCalculatorPromoWidget>
    )
}


const ScCalculatorPromoWidget = styled.div`
    display: block;

    > div {
        display: flex;
        
        column-gap: 10px;
        
        ${ScInput} {
            flex: 1;
        }
    }

`

export default CalculatorPromoWidget;