import {useState} from "react";
import {useNavigate} from "react-router-dom";
import styled, {keyframes, useTheme} from "styled-components";
import {useSnackbar} from "@mui/base";
import auth from "../api/auth";
import {FieldValues, useForm} from "react-hook-form";
import {Link} from "react-router-dom";
import {BaseUserCredentialsModel} from "../api/users";
import ScInput from "../components/styled/ScInput";
import ScPopupForm from "../components/styled/ScPopupForm";
import {ScFilledButton} from "../components/styled/ScButton";


/**
 * @Composable
 * Компонент страницы авторизации
 * @constructor
 */
export function SignInPage() {
    const navigate = useNavigate();
    const { typography } = useTheme();

    const [snackBarText, setSnackBarText] = useState("");
    const onSignInSuccessful = () => {
        handeOpenSnackBar("Вы успешно вошли")
        navigate("/cabinet")
    }

    auth.ensureSignedIn()
        .then(isSuccess => {
            if (isSuccess) {
                onSignInSuccessful()
            }
        })

    const {
        register,
        handleSubmit ,
        formState: { errors }
    } = useForm();

    const onFormValidationSubmit = (data: FieldValues) => {
        auth.signIn(new BaseUserCredentialsModel(data))
            .then(isSuccess => {
                if (isSuccess) {
                    onSignInSuccessful()
                } else {
                    handeOpenSnackBar("Неправильный логин или пароль")
                }

            })
            .catch(error => {
                console.log(error);
                handeOpenSnackBar("Ошибка авторизации")
            })
    }
    const onFormValidationError = (error: FieldValues) => {
        console.log(error)
    }

    const [isSnackBarOpened, setIsSnackBarOpened] = useState(false);

    const handleCloseSnackBar = () => {
        setIsSnackBarOpened(false);
    };

    const { getRootProps } = useSnackbar({
        onClose: handleCloseSnackBar,
        open: isSnackBarOpened,
        autoHideDuration: 5000,
    });

    const handeOpenSnackBar = (text: string) => {
        setSnackBarText(text);
        setIsSnackBarOpened(true);
    };

    return (
        <ScSignInPage>
            <ScPopupForm>
                <form className={'inputs-column'}>
                    <ScInput
                        {...register("email", {
                            required: {
                                value: true,
                                message: "Заполните поле"
                            },
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Введите корректный email"
                            }
                        })}
                        placeholder={"Email"}

                        aria-invalid={errors["email"] ? 'true' : 'false'}
                    />
                    <span className={"error-text"}>
                        {errors["email"] ? errors["email"].message.toString() : ""}
                    </span>

                    <ScInput
                        {...register("password", {
                            required: {
                                value: true,
                                message: "Заполните поле"
                            }
                        })}
                        placeholder={"Пароль"}
                        type={"password"}

                        aria-invalid={errors["password"] ? 'true' : 'false'}
                    />
                    <span className={"error-text"}>
                        {errors["password"] ? errors["password"].message.toString() : ""}
                    </span>
                </form>

                <ScFilledButton fontSize={typography.fontSizes.large} style={{padding: '0.5em'}} onClick={handleSubmit(onFormValidationSubmit, onFormValidationError)}>Вход</ScFilledButton>

                <div className={"lowergrid"}>
                    <span>Нет аккаунта?</span>
                    <span><Link to="/signup">Зарегестрироваться</Link></span>
                </div>
            </ScPopupForm>
            {isSnackBarOpened && <CustomSnackbar {...getRootProps()}>{snackBarText}</CustomSnackbar>}
        </ScSignInPage>
    )
}

const ScSignInPage = styled.div`
    display: grid;
    align-items: center;
    justify-items: center;
    
    width: 100%;
    height: 100%;
    
    .inputs-column {
        display: flex;
        flex-direction: column;
        
        min-width: 300px;
        
        align-self: center;

        row-gap: 2px;
    }
    
    ${ScPopupForm} > ${ScFilledButton} {
        margin-top: 7px;
    }
    
    div.lowergrid {
        display: flex;
        justify-content: space-between;
        color: ${props => props.theme.colors.secondary};
        font-size: ${props => props.theme.typography.fontSizes.small};
        
        > *:last-child {
            text-decoration: underline;
            font-weight: 400;
        }
    }
    
    .error-text {
        color: red;
        font-size: 0.8em;
        min-height: 0.8em;
    }
`

const snackbarInRight = keyframes`
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
`;

const CustomSnackbar = styled.div`
    position: fixed;
    z-index: 5500;
    display: flex;
    right: 16px;
    bottom: 16px;
    left: auto;
    justify-content: space-between;
    max-width: 560px;
    min-width: 300px;
    background-color: ${props => props.theme.colors.secondaryContainer};
    border-radius: 8px;
    border: 1px solid ${props => props.theme.colors.secondary};

    padding: 0.75rem;
    color: ${props => props.theme.colors.tertiary};
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    animation: ${snackbarInRight} 200ms;
    transition: transform 0.2s ease-out;
`

export default SignInPage;