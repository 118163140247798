import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TFlag = {
  flag: boolean;
	spoilerOne: boolean;
	spoilerTwo: boolean;
	spoilerThree: boolean;
	spoilerFour: boolean;
	spoilerFive: boolean;
}

const initialState: TFlag = {
	flag: true,
	spoilerOne: false,
	spoilerTwo: false,
	spoilerThree: false,
	spoilerFour: false,
	spoilerFive: false,
};

export const flagSlice = createSlice({
	name: 'flag',
	initialState,
	reducers: {
		// succes
    flagFalse(state, action: PayloadAction<boolean>) {
			state.flag = action.payload;
		},
		flagSpoilerOne(state, action: PayloadAction<boolean>) {
			state.spoilerOne = action.payload;
		},
		flagSpoilerTwo(state, action: PayloadAction<boolean>) {
			state.spoilerTwo = action.payload;
		},
		flagSpoilerThree(state, action: PayloadAction<boolean>) {
			state.spoilerThree = action.payload;
		},
		flagSpoilerFour(state, action: PayloadAction<boolean>) {
			state.spoilerFour = action.payload;
		},
		flagSpoilerFive(state, action: PayloadAction<boolean>) {
			state.spoilerFive = action.payload;
		},
		// reset state
		flagReset() {
			return initialState;
		},
    spoilerReset() {
			return initialState;
		},
	},
});

export default flagSlice.reducer;
