import { useMediaQuery } from 'react-responsive';
import MainPage from './MainPage';
import MainPageMobile from './MainPageMobile';

/**
 * @function {Main} - Основной компонент заглавной страницы для приложения "Клининг". 
 * В зависимости от медиа-запроса возвращает десктопную или мобильную версиюю.
 * @returns {React.JSX.Element} - JSX-элемент, представляющий заглавную страницу.
 */

function Main(): React.JSX.Element {

const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  return (
    <>
      {isDesktopOrLaptop ? (
        <MainPage />
      ) : isTabletOrMobile ? (
        <MainPageMobile />
      ) : null}
    </>
  );
};

export default Main;
