interface IFakePerson {
    surname: string,
    name: string,
    patronym: string
}

const persons : IFakePerson[] = [
    { surname: 'Соловьёв', name: 'Алексей', patronym: 'Игоревич' },
    { surname: 'Воробьёва', name: 'Марина', patronym: 'Сергеевна' },
    { surname: 'Орлов', name: 'Игорь', patronym: 'Владимирович' },
    { surname: 'Синицына', name: 'Ольга', patronym: 'Андреевна' },
    { surname: 'Журавлёв', name: 'Сергей', patronym: 'Николаевич' },
    { surname: 'Соколова', name: 'Наталья', patronym: 'Алексеевна' },
    { surname: 'Ласточкин', name: 'Дмитрий', patronym: 'Петрович' },
    { surname: 'Ворона', name: 'Елена', patronym: 'Васильевна' },
    { surname: 'Галкин', name: 'Виктор', patronym: 'Евгеньевич' },
    { surname: 'Лебедева', name: 'Анна', patronym: 'Павловна' }
];

export function getFakeShortPerson() {
    const person = persons[Math.floor(Math.random() * persons.length)];
    return `${person.surname} ${person.name.slice(0, 1)}.${person.patronym.slice(0, 1)}`
}