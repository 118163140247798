import { TZulipResponseCheck, TZulipResponseData, TZulipResponseKey, TZulipResponseTime } from '../../../response/zulipResponse';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TZulip = {
  check: string | null;
	key: string;
	isFetching: boolean;
	error: string;
	data: {};
	timeStamp: number | null;
}

const initialState: TZulip = {
	check: null,
	key: '',
	timeStamp: null,
	isFetching: false,
	error: '',
	data: {
		ido: '',
		name: '',
		surname: '',
		email: '',
	}
};

export const zulipSlice = createSlice({
	name: 'zulip',
	initialState,
	reducers: {
		// start
		zulipFetching(state) {
			state.isFetching = true;
		},
		// succes
    zulipCheck(state, action: PayloadAction<TZulipResponseCheck>) {
			state.isFetching = false;
			state.check = action.payload;
			state.error = '';
		},
		zulipData(state, action: PayloadAction<TZulipResponseData>) {
			state.isFetching = false;
			state.data = action.payload;
			state.error = '';
		},
		zulipKey(state, action: PayloadAction<TZulipResponseKey>) {
			state.isFetching = false;
			state.key = action.payload;
			state.error = '';
		},
		zulipTimeStamp(state, action: PayloadAction<TZulipResponseTime>) {
			state.isFetching = false;
			state.timeStamp = action.payload;
			state.error = '';
		},
		// Error
    zulipError(state, action: PayloadAction<string>) {
			state.isFetching = false;
      state.error = action.payload;
    },
		// reset state
		zulipReset() {
			return initialState;
		},
	},
});

export default zulipSlice.reducer;
