import {ReactElement} from "react";
import {Outlet} from "react-router-dom";
import Loading from "../components/Loading";

type EnsureLoadedProps = {
    isLoading: boolean
}


/**
 * @Composable
 * Обёртка для удостоверения, что загрузка завершена
 * @constructor
 */
function EnsureLoaded(props: EnsureLoadedProps) : ReactElement {
    return props.isLoading ? <Loading/> : <Outlet/>;
}

export default EnsureLoaded;