import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import style from './Spisok.module.scss';

type TModalProps = {
  open: boolean;
  modal_id: string;
  onClose: () => void;
};

/**
 * @function {SpisokModal} - Модальное окно для просмотра услуг, оферты и соглашения.
 * @returns {React.JSX.Element} - JSX-элемент, представляющий отображение модального окна.
 */

export const SpisokModal = ({ open, modal_id, onClose }: TModalProps): React.JSX.Element => {

  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})

  // скрываем скролл из body 
  useEffect(() => {
    if(open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    }
  },[open]);

  if(!open) return null;
  return (
    <div 
      className={isDesktopOrLaptop ? style.main : style.mainMobile}
      onClick={(e: React.MouseEvent<HTMLImageElement>) => {
        e.stopPropagation();
      }}
    >
      {modal_id == 'support_default' ? (
          <div className={style.windowOne} style={{'height' : '1170px'}}>
            <div className={style.title}>Поддерживающая уборка "Стандарт"</div>
            <div className={style.headingOne}>Кухня:</div>
            <ul className={style.textOne}>
              <li>Не удаляем жировые отложения</li>
              <li>Моем грязную посуду</li>
              <li>Выносим мусор и дезинфицируем мусорное ведро</li>
              <li>Моем варочную поверхность, протираем плиту снаружи</li>
              <li>Протираем холодильник снаружи от пыли и не сильных загрязнений</li>
              <li>Протираем микроволновку снаружи от пыли и не сильных загрязнений</li>
              <li>Протираем радиатор снаружи от пыли и не сильных загрязнений</li>
              <li>Протираем пыль со всех открытых горизонтальных поверхностей на высоте до 1,5 метров, в том числе
                подоконник, убираем не сильные загрязнения
              </li>
              <li>Моем фартук и столешницу, убираем не сильные загрязнения</li>
              <li>Протираем хромированные части мебели</li>
              <li>Моем смеситель и раковину, убираем налет</li>
              <li>Протираем стол и стулья, в том числе ножки</li>
              <li>Протираем выключатели и розетки</li>
              <li>Протираем настенные светильники</li>
              <li>Моем пол (без плинтусов), отодвигая не тяжелые предметы</li>
            </ul>

            <div style={{'top': '460px'}} className={style.headingTwo}>Комната:</div>
            <ul style={{'top': '490px'}} className={style.textTwo}>
              <li>Аккуратно раскладываем вещи по местам</li>
              <li>Заправляем диван/кровать, не меняем постельное белье</li>
              <li>Протираем пыль со всех открытых горизонтальных поверхностей на высоте до 1,5 метров, в том числе
                подоконник
              </li>
              <li>Протираем радиатор снаружи от пыли и не сильных загрязнений</li>
              <li>Протираем выключатели и розетки</li>
              <li>Протираем светильники</li>
              <li>Протираем зеркала на высоте до 1,5 метров</li>
              <li>Пылесосим ковер пылесосом клиента</li>
              <li>Моем пол (без плинтусов), отодвигая не тяжелые предметы</li>
            </ul>

            <div style={{'top': '660px'}}  className={style.headingThree}>Коридор:</div>
            <ul style={{'top': '685px'}}  className={style.textThree}>
              <li>Аккуратно раскладываем вещи и обувь по местам</li>
              <li>Протираем пыль со всех открытых горизонтальных поверхностей на высоте до 1,5 метров</li>
              <li>Протираем выключатели и розетки</li>
              <li>Протираем светильники</li>
              <li>Протираем зеркала на высоте до 1,5 метров</li>
              <li>Пылесосим ковер пылесосом клиента</li>
              <li>Моем пол (без плинтусов), отодвигая не тяжелые предметы</li>
            </ul>

            <div style={{'top': '820px'}}  className={style.headingFour}>С/У:</div>
            <ul style={{'top': '840px'}}  className={style.textFour}>
              <li>Выносим мусор и дезинфицируем мусорное ведро</li>
              <li>Моем смеситель и раковину, убираем налет</li>
              <li>Моем ванную/душевую кабину, лейку, убираем налет</li>
              <li>Моем унитаз, дезинфицируем его</li>
              <li>Протираем стены на высоте до 1,5 метров</li>
              <li>Протираем полотенцесушитель от пыли и не сильных загрязнений</li>
              <li>Протираем держатели от пыли и не сильных загрязнений</li>
              <li>Протираем зеркало</li>
              <li>Протираем выключатели и розетки</li>
              <li>Протираем светильники</li>
              <li>Чистим коврик</li>
              <li>Моем пол (без плинтусов), отодвигая не тяжелые предметы</li>
            </ul>

            <button style={{'top': '1092px'}}  type="button" className={style.btn} onClick={onClose}>Закрыть</button>
          </div>
      ) : modal_id == 'support_light' ? (
          <div className={style.windowOne} style={{'height': '909px'}}>
            <div className={style.title}>Поддерживающая уборка "Лайт"</div>
            <div className={style.headingOne}>Кухня. Не удаляем жировые отложения:</div>
            <ul className={style.textOne}>
              <li>Моем грязную посуду</li>
              <li>Выносим мусор и дезинфицируем мусорное ведро</li>
              <li>Моем варочную поверхность, протираем плиту снаружи</li>
              <li>Протираем холодильник снаружи от пыли и не сильных загрязнений</li>
              <li>Моем фартук и столешницу, убираем не сильные загрязнения</li>
              <li>Протираем пыль со всех открытых горизонтальных поверхностей на высоте до 1,5 метров, в том числе
                подоконник, убираем не сильные загрязнения
              </li>
              <li>Протираем хромированные части мебели</li>
              <li>Моем смеситель и раковину, убираем налет</li>
              <li>Протираем стол и стулья, в том числе ножки</li>
              <li>Моем пол (без плинтусов), отодвигая не тяжелые предметы</li>
            </ul>

            <div className={style.headingTwo} style={{'top': '394px'}}>Комната:</div>
            <ul className={style.textTwo} style={{'top': '420px'}}>
              <li>Аккуратно раскладываем вещи по местам</li>
              <li>Заправляем диван/кровать, не меняем постельное белье</li>
              <li>Протираем пыль со всех открытых горизонтальных поверхностей на высоте до 1,5 метров, в том числе
                подоконник
              </li>
              <li>Протираем зеркала на высоте до 1,5 метров</li>
              <li>Пылесосим ковер пылесосом клиента</li>
              <li>Моем пол (без плинтусов), отодвигая не тяжелые предметы</li>
            </ul>

            <div className={style.headingThree} style={{'top': '540px'}}>Коридор:</div>
            <ul className={style.textThree} style={{'top': '565px'}}>
              <li>Аккуратно раскладываем вещи и обувь по местам</li>
              <li>Протираем пыль со всех открытых горизонтальных поверхностей на высоте до 1,5 метров</li>
              <li>Протираем зеркала на высоте до 1,5 метров</li>
              <li>Пылесосим ковер пылесосом клиента</li>
              <li>Моем пол (без плинтусов), отодвигая не тяжелые предметы</li>
            </ul>

            <div className={style.headingFour} style={{'top': '660px'}}>С/У:</div>
            <ul className={style.textFour} style={{'top': '680px'}}>
              <li>Выносим мусор и дезинфицируем мусорное ведро</li>
              <li>Моем смеситель и раковину, убираем налет</li>
              <li>Моем ванную/душевую кабину, лейку, убираем налет</li>
              <li>Моем унитаз, дезинфицируем его</li>
              <li>Протираем зеркало</li>
              <li>Чистим коврик</li>
              <li>Моем пол (без плинтусов), отодвигая не тяжелые предметы</li>
            </ul>

            <button type="button" className={style.btn} onClick={onClose} style={{'top' : '850px' }} >Закрыть</button>
          </div>
      ) : modal_id == 'support_premium' ? (
          <div className={style.windowTwo} style={{'height' : '1350px'}}>
            <div className={style.title}>Поддерживающая уборка "Премиум"</div>
            <div className={style.headingOne} >Кухня:</div>
            <ul className={style.textOne}>
              <li>Моем грязную посуду</li>
              <li>Выносим мусор и дезинфицируем мусорное ведро</li>
              <li>Моем варочную поверхность, убираем жировые отложения, протираем плиту снаружи</li>
              <li>Протираем холодильник снаружи, в том числе убираем жировые отложения</li>
              <li>Моем микроволновку снаружи и внутри</li>
              <li>Чистим кофемашину, мультиварку, чайник снаружи</li>
              <li>Моем фартук и столешницу, убираем жировые отложения</li>
              <li>Протираем радиатор снаружи</li>
              <li>Протираем пыль со всех открытых горизонтальных поверхностей по всей высоте, в том числе подоконник
              </li>
              <li>Протираем кухонный гарнитур по всей поверхности, кроме верха подвесных шкафов</li>
              <li>Протираем хромированные части мебели</li>
              <li>Моем смеситель и раковину</li>
              <li>Протираем стол и стулья, в том числе ножки</li>
              <li>Протираем выключатели и розетки</li>
              <li>Протираем настенные светильники</li>
              <li>Моем дверь, дезинфицируем дверные ручки</li>
              <li>Моем пол, отодвигая не тяжелые предметы</li>
              <li>Моем плинтусы</li>
            </ul>

            <div className={style.headingTwo} style={{'top' : '505px'}}>Комната:</div>
            <ul style={{'top' : '530px'}} className={style.textTwo}>
              <li>Аккуратно раскладываем вещи по местам</li>
              <li>Заправляем диван/кровать, не меняем постельное белье</li>
              <li>Протираем пыль со всех открытых горизонтальных поверхностей по всей высоте, в том числе подоконник
              </li>
              <li>Протираем радиатор снаружи от пыли и не сильных загрязнений</li>
              <li>Протираем выключатели и розетки</li>
              <li>Протираем светильники</li>
              <li>Протираем зеркала</li>
              <li>Пылесосим ковер пылесосом клиента</li>
              <li>Моем дверь, дезинфицируем дверные ручки</li>
              <li>Моем пол, отодвигая не тяжелые предметы</li>
              <li>Моем плинтусы</li>
            </ul>

            <div style={{'top' : '740px'}} className={style.headingThree}>Коридор:</div>
            <ul style={{'top' : '765px'}} className={style.textThree}>
              <li>Аккуратно раскладываем вещи и обувь по местам</li>
              <li>Протираем пыль со всех открытых горизонтальных поверхностей по всей высоте</li>
              <li>Протираем выключатели и розетки</li>
              <li>Протираем светильники</li>
              <li>Протираем зеркала</li>
              <li>Моем дверь, дезинфицируем дверные ручки, трубку домофона</li>
              <li>Пылесосим ковер пылесосом клиента</li>
              <li>Моем пол, отодвигая не тяжелые предметы</li>
              <li>Моем плинтусы</li>
            </ul>

            <div style={{'top' : '940px'}} className={style.headingFour}>С/У:</div>
            <ul  style={{'top' : '960px'}} className={style.textFour}>
              <li>Выносим мусор и дезинфицируем мусорное ведро</li>
              <li>Моем смеситель и раковину, убираем налет</li>
              <li>Моем ванную/душевую кабину, лейку, убираем налет</li>
              <li>Моем унитаз, дезинфицируем его</li>
              <li>Протираем стены по всей высоте</li>
              <li>Протираем полотенцесушитель от пыли и не сильных загрязнений</li>
              <li>Протираем держатели от пыли и не сильных загрязнений</li>
              <li>Протираем пыль на всех доступных поверхностях</li>
              <li>Протираем зеркало</li>
              <li>Протираем выключатели и розетки</li>
              <li>Протираем светильники</li>
              <li>Моем дверь, дезинфицируем дверные ручки</li>
              <li>Чистим коврик</li>
              <li>Моем пол, отодвигая не тяжелые предметы</li>
            </ul>
            <button  style={{'top' : '1260px'}} type="button" className={style.btn} onClick={onClose}>Закрыть</button>
          </div>
      ) : modal_id == 'general_default' ? (
          <div className={style.windowTwo}>
            <div className={style.title}>Генеральная уборка "Стандарт"</div>
            <div className={style.headingOne}>1. Комнаты и прихожая:</div>
            <ul className={style.textOne}>
              <li>Вымыть пол</li>
              <li>Вымыть окна внутри</li>
              <li>Протирка радиаторов</li>
              <li>Вынести мусор и поменять мусорные пакеты в корзинах</li>
              <li>Разложить вещи аккуратно</li>
              <li>Протереть пыль на всех свободных поверхностях</li>
              <li>Протереть от пыли торшеры и бра</li>
              <li>Протереть дверные ручки</li>
              <li>Протереть зеркала и зеркальные поверхности</li>
              <li>Протереть дверные блоки</li>
              <li>Пропылесосить пол</li>
              <li>Вымыть пол и плинтуса</li>
              <li>Вымыть шкафы и тумбы снаружи и сверху</li>
              <li>Вымыть шкафы и тумбы внутри при условии, что они пустые</li>
              <li>Протереть от пыли все крупные предметы</li>
            </ul>
            <div className={style.headingTwo}>2. Санузлы:</div>
            <ul className={style.textTwo}>
              <li>Вымыть фаянс</li>
              <li>Протереть краны</li>
              <li>Протереть стены</li>
              <li>Протереть пыль</li>
              <li>Разложить вещи аккуратно</li>
              <li>Вымыть пол</li>
              <li>Протереть зеркала</li>
              <li>Вымыть и обеспылить вытяжку в санузле</li>
            </ul>
            <div className={style.headingThree}>3. Кухня:</div>
            <ul className={style.textThree}>
              <li>Протереть холодильник снаружи</li>
              <li>Вымыть холодильник внутри</li>
              <li>Вымыть варочную поверхность</li>
              <li>Вымыть духовой шкаф</li>
              <li>Вымыть микроволновую печь</li>
              <li>Протереть и дезинфицировать горизонтальные поверхности</li>
              <li>Протереть хромированные части мебели</li>
              <li>Вымыть пол</li>
            </ul>
            <div className={style.headingFour}>Доп услуги (плюс к основному чеку):</div>
            <ul className={style.textFour}>
              <li>Мытье окон снаружи</li>
              <li>Мытье оконо на балконе внутри</li>
            </ul>
            <button type="button" className={style.btn} onClick={onClose}>Закрыть</button>
          </div>
      ) : modal_id == 'general_assoc' ? (
          <div className={style.windowThree}>
            <div className={style.title}>Генеральная уборка "Ассоциальная"</div>
            <div className={style.headingService}>Состав услуги:</div>
            <ul className={style.textService}>
              <li>Сбор/вынос мусора</li>
              <li>Удаление сильных загрязнений</li>
              <li>Дезинфекция</li>
              <li>Устранение запахов</li>
            </ul>
            <div className={style.headingOne}>1. Комнаты и прихожая:</div>
            <ul className={style.textOne}>
              <li>Вымыть пол</li>
              <li>Вымыть окна внутри</li>
              <li>Протирка радиаторов</li>
            <li>Вынести мусор и поменять мусорные пакеты в корзинах</li>
            <li>Разложить вещи аккуратно</li>
            <li>Протереть пыль на всех свободных поверхностях</li>
            <li>Протереть от пыли торшеры и бра</li>
            <li>Протереть дверные ручки</li>
            <li>Протереть зеркала и зеркальные поверхности</li>
            <li>Протереть дверные блоки</li>
            <li>Пропылесосить пол</li>
            <li>Вымыть пол и плинтуса</li>
            <li>Вымыть шкафы и тумбы снаружи и сверху</li>
            <li>Вымыть шкафы и тумбы внутри при условии, что они пустые</li>
            <li>Протереть от пыли все крупные предметы</li>
          </ul>
          <div className={style.headingTwo}>2. Санузлы:</div>
          <ul className={style.textTwo}>
            <li>Вымыть фаянс</li>
            <li>Протереть краны</li>
            <li>Протереть стены</li>
            <li>Протереть пыль</li>
            <li>Вымыть пол</li>
            <li>Протереть зеркала</li>
            <li>Вымыть и обеспылить вытяжку в санузле</li>
          </ul>
          <div className={style.headingThree}>3. Кухня:</div>
          <ul className={style.textThree}>
            <li>Протереть холодильник снаружи</li>
            <li>Вымыть холодильник внутри</li>
            <li>Вымыть варочную поверхность</li>
            <li>Вымыть духовой шкаф</li>
            <li>Вымыть микроволновую печь</li>
            <li>Протереть и дезинфицировать горизонтальные поверхности</li>
            <li>Протереть хромированные части мебели</li>
            <li>Вымыть пол</li>
          </ul>
          <button type="button" className={style.btn} onClick={onClose}>Закрыть</button>
        </div>
      ) : modal_id == 'general_party' ? (
        <div className={style.windowFour}>
          <div className={style.title}>Генеральная уборка "Последствия вечеринки"</div>
          <div className={style.headingService}>Состав услуги:</div>
          <ul className={style.textService}>
            <li>Приезд клинера в течении 3 часов с момента оформления заказа</li>
            <li>Удаление органических загрязнений</li>
            <li>Вынос мусора</li>
            <li>Устранение запахов</li>
          </ul>
          <div className={style.headingOne}>1. Комнаты и прихожая:</div>
          <ul className={style.textOne}>
            <li>Вымыть пол</li>
            <li>Вынести мусор и поменять мусорные пакеты в корзинах</li>
            <li>Протереть зеркала и зеркальные поверхности</li>
            <li>Протереть дверные блоки</li>
            <li>Пропылесосить пол</li>
            <li>Вымыть шкафы и тумбы снаружи и сверху</li>
          </ul>
          <div className={style.headingTwo}>2. Санузлы:</div>
          <ul className={style.textTwo}>
            <li>Вымыть фаянс</li>
            <li>Протереть краны</li>
            <li>Протереть стены</li>
            <li>Протереть пыль</li>
            <li>Вымыть пол</li>
            <li>Протереть зеркала</li>
          </ul>
          <div className={style.headingThree}>3. Кухня:</div>
          <ul className={style.textThree}>
            <li>Протереть холодильник снаружи</li>
            <li>Вымыть холодильник внутри</li>
            <li>Вымыть варочную поверхность</li>
            <li>Вымыть духовой шкаф</li>
            <li>Вымыть микроволновую печь</li>
            <li>Протереть и дезинфицировать горизонтальные поверхности</li>
            <li>Протереть хромированные части мебели</li>
            <li>Вымыть пол</li>
          </ul>
          <div className={style.headingFour}>Доп услуги (плюс к основному чеку):</div>
          <ul className={style.textFour}>
            <li>Квартира находится вне черты города</li>
          </ul>
          <button type="button" className={style.btn} onClick={onClose}>Закрыть</button>
        </div>
      ) : modal_id == 'general_presold' ? (
        <div className={style.windowFive}>
          <div className={style.title}>Генеральная уборка "Предпродажная"</div>
          <div className={style.headingOne}>1. Комнаты и прихожая:</div>
          <ul className={style.textOne}>
            <li>Вымыть пол</li>
            <li>Вымыть окна внутри/снаружи</li>
            <li>Протирка радиаторов</li>
            <li>Вынос мусора</li>
            <li>Протереть пыль на всех свободных поверхностях</li>
            <li>Протереть от пыли торшеры и бра</li>
            <li>Протереть дверные ручки</li>
            <li>Протереть зеркала и зеркальные поверхности</li>
            <li>Протереть дверные блоки</li>
            <li>Вымыть шкафы и тумбы снаружи и сверху</li>
            <li>Протереть от пыли все крупные предметы</li>
          </ul>
          <div className={style.headingTwo}>2. Санузлы:</div>
          <ul className={style.textTwo}>
            <li>Вымыть фаянс</li>
            <li>Протереть краны</li>
            <li>Протереть стены</li>
            <li>Вымыть пол</li>
            <li>Протереть зеркала</li>
            <li>Вымыть и обеспылить вытяжку в санузле</li>
          </ul>
          <div className={style.headingThree}>3. Кухня:</div>
          <ul className={style.textThree}>
            <li>Вымыть варочную поверхность</li>
            <li>Вымыть духовой шкаф</li>
            <li>Вымыть микроволновую печь</li>
            <li>Протереть и дезинфицировать горизонтальные поверхности</li>
            <li>Протереть хромированные части мебели</li>
            <li>Вымыть пол</li>
          </ul>
          <div className={style.headingFour}>Доп услуги (плюс к основному чеку):</div>
          <ul className={style.textFour}>
            <li>Мытье балкона</li>
          </ul>
          <button type="button" className={style.btn} onClick={onClose}>Закрыть</button>
        </div>
      ) : modal_id == 'build_default' ? (
        <div className={style.windowSix}>
          <div className={style.title}>Послестроительная</div>
          <div className={style.headingOne}>1. Комнаты и прихожая:</div>
          <ul className={style.textOne}>
            <li>Вынести крупный мусор</li>
            <li>Вымыть пол и плинтуса</li>
            <li>Вымыть окна внутри</li>
            <li>Вымыть окна снаружи</li>
            <li>Протирка радиаторов</li>
            <li>Обеспылить все горизонтальные поверхности</li>
            <li>Протереть от пыли торшеры и бра</li>
            <li>Вымыть дверные блоки</li>
            <li>Протереть зеркала и зеркальные поверхности</li>
            <li>Вымыть шкафы и тумбы снаружи и сверху</li>
            <li>Вымыть шкафы и тумбы внутри при условии, что они пустые</li>
            <li>Протереть от пыли все крупные предметы</li>
          </ul>
          <div className={style.headingTwo}>2. Санузлы:</div>
          <ul className={style.textTwo}>
            <li>Вымыть фаянс</li>
            <li>Протереть краны</li>
            <li>Протереть стены</li>
            <li>Протереть пыль</li>
            <li>Вымыть пол</li>
            <li>Протереть зеркала</li>
            <li>Вымыть и обеспылить вытяжку в санузле</li>
          </ul>
          <div className={style.headingThree}>3. Кухня:</div>
          <ul className={style.textThree}>
            <li>Протереть холодильник снаружи</li>
            <li>Обеспылить горизонтальные поверхности</li>
            <li>Вымыть окна внутри</li>
            <li>Вымыть окна снаружи</li>
            <li>Протереть хромированные части мебели</li>
            <li>Вымыть пол</li>
          </ul>
          <button type="button" className={style.btn} onClick={onClose}>Закрыть</button>
        </div>
      ) : modal_id == 'offer' ? (
        <div className={style.windowOfer}>
          <div className={style.title}>OФЕРТА</div>
          <div className={style.caption}>на заключение Договора</div>
          <div className={style.heading}>
            <div className={style.text}>Настоящий документ, постоянно размещенный в сети Интернет на сайте является публичной офертой в соответствии с пунктом 2 статьи 437 Гражданского Кодекса Российской Федерации на заключение договора оказания услуг удаленного доступа от лица Агрегатора, посредством использования Платформы-агрегатора (далее - Договор), с любым заинтересованным физическим или юридическим лицом (далее - Пользователь).</div>
            <div className={style.text}><span><span style={{ fontWeight: '700' }}>Агрегатор</span>&nbsp;- ООО «КАКТУС» ИНН: 2901314285, подробная информация в разделе 13 настоящего Договора.</span></div>
            <div className={style.text}><span><span style={{ fontWeight: '700' }}>Платформа–агрегатор</span>&nbsp;- электронный ресурс Агрегатора услуг расположенный в сети Интернет по адресу:&nbsp;<a target="_blank" rel="noreferrer" href="https://lastik.vip/">https://lastik.vip/</a></span></div>
            <div className={style.text}><span><span style={{ fontWeight: '700' }}>Партнер</span>&nbsp;– как единственном, так и во множественном числе, означает третье лицо, с которым у Агрегатора имеются персональные условия сотрудничества, которые Агрегатор предлагает Пользователям по средствам Платформы- Агрегатора, заключен договор и которого Агрегатор привлекает в качестве исполнителя по настоящему договору, для оказания услуг Пользователю.</span></div>
            <div className={style.text}>Надлежащим акцептом настоящей оферты в соответствии со статьей 438 Гражданского кодекса Российской Федерации является совершение Пользователем одного из следующих действий:</div>
            <div className={style.text}>А) Нажатия кнопок «Отправить заявку», «Заказать звонок», «Регистрация» на Платформе-агрегаторе, или в иных местах, где размещена соответствующая кнопка, для получения доступа к отдельным частям Платформы-агрегатора,</div>
            <div className={style.text}>Б) Оплата выбранной услуги, согласно тарифу указанного на Платформе-агрегаторе в соответствующем разделе, или способом предусмотренным Платформой – агрегатором в соответствии с действующим законодательством</div>
            <div className={style.text}>Датой акцепта Оферты Пользователем (датой заключения Договора) считается дата оплаты услуг по выбранной категории согласно тарифам размещенных на Платформе-агрегаторе.</div>
          </div>
          <div className={style.titles}>Договор</div>
          <div className={style.titlesOne}>1. Предмет договора</div>
          <div className={style.headingOne}>
            <div className={style.text}>1.1.Агрегатор обязуется предоставить Пользователю через информационно- коммуникационную сеть “Интернет” удаленный доступ к Платформе-агрегатору, где размещены перечень услуг и тарифы, при этом тарифы указаны с учетом имеющихся скидок и бонусов, предоставляемых Агрегатором Пользователю в случаи акцепта оферты. Пользователь выбирает на Платформе-агрегаторе необходимую услугу и оплачивает ее стоимость, согласно тарифам указанных на Платформе-агрегаторе в соответствующем разделе, на счет Агрегатора или иным предложенным на Платформе-агрегаторе способом. После получения оплаты от Пользователя, Агрегатор организовывает оказание выбранной и оплаченной Пользователем услуги с помощью Партнеров. Услуга оказывается на условиях стопроцентной предоплаты.</div>
            <div className={style.text}>1.2.По дополнительному согласованию с Агрегатором, доступ может быть предоставлен третьему лицу, на которое укажет Пользователь, при условии несения ответственности за его действия, как за свои собственные. В этом случае, в рамках исполнения Договора третье лицо приравнивается к Пользователю.</div>
            <div className={style.text}>1.3.Агрегатор самостоятельно администрирует Платформе-агрегатор, в том числе обновляет информацию на ней. Оформление и наполнение Платформы-агрегатора принадлежит Агрегатору, при предоставлении доступа Пользователю к Платформе- агрегатору у Пользователя не возникает никаких прав на Платформу-агрегатор, кроме как индивидуально использовать ее для информационных целей, без возможности копирования, тиражирования материалов предоставленных Агрегатором или Партнерами в рамках оказания услуг Пользователю.</div>
            <div className={style.text}>1.4.Пользователь соглашается, что услуга считается оказанной Агрегатором с момента предоставления услуги Партнером.</div>
          </div>
          <div className={style.titlesTwo}>2. Права и обязанности Сторон.</div>
          <div className={style.headingTwo}>
            <div className={style.text}><span style={{ fontWeight: '700' }}>2.1.Агрегатора обязан:</span></div>
            <div className={style.text}>2.1.1.Администрировать Платформу-агрегатор, следить за актуальностью информации на ней.</div>
            <div className={style.text}>2.1.2.Оказать Пользователю с помощью Партнеров оплаченную Пользователем услугу.</div>
            <div className={style.text}>2.1.3.Нести ответственность перед Пользователем за качество оказанных услуг, в том числе оказанных услуг Партнерами.</div>
            <div className={style.text}><span>2.1.4.Осуществлять информационную поддержку Пользователя по вопросам оказания Услуг и работы Платформы-агрегатора по рабочим дням с 09:00 до 20:00 по Московскому времени. Все вопросы по поддержке направляются Заказчиком по электронному адресу: <span style={{ fontWeight: '700' }}>001@dels.pro</span></span></div>
            <div className={style.text}>2.1.5.В случаи возникновения споров по количеству или качеству оказанных Партнерами услуг, принимать активное участие в разрешении спора.</div>
            <div className={style.text}>2.1.6.Вернуть предоплату Пользователю в полном объеме, за минусом комиссий сторонней платежной системы в случаи отказа от Договора любой из Сторон, при условии, что Партнер не начал оказывать услугу Пользователю.</div>
            <div className={style.text}><span style={{ fontWeight: '700' }}>2.2. Пользователь обязан:</span></div>
            <div className={style.text}>2.2.1.Своевременно и в полном объеме оплатить стоимость услуг, выбранных на Платформе-агрегаторе.</div>
            <div className={style.text}>2.2.2.Своевременно предоставлять полную и достоверную информацию, необходимую для оказания услуг (в том числе при регистрации на Платформе-агрегаторе).</div>
            <div className={style.text}>2.2.3.Использовать результаты услуг исключительно в личных целях, не копировать, не тиражировать, не доводить результаты услуг до третьих лиц, в том числе не ссылаться на результаты оказанных услуг в открытом доступе (интервью, статьи, блоги и тд).</div>
            <div className={style.text}>2.2.4.Соблюдать этические нормы поведения при общении с сотрудниками Агрегатора, Партнерами и так или иначе связанными с выполнение услуг, а также с третьими лицами на Платформе-агрегаторе.</div>
            <div className={style.text}><span style={{ fontWeight: '700' }}>2.3. Агрегатора вправе:</span></div>
            <div className={style.text}>2.3.1.Без согласования с Пользователем привлекать третьих лиц для исполнения настоящего Договора, оставаясь ответственным за действия таких лиц, как за свои собственные.</div>
            <div className={style.text}>2.3.2.Запрашивать у Пользователя всю необходимую информацию, документы для надлежащего исполнения обязательств по настоящему Договору.</div>
            <div className={style.text}>2.3.3.В одностороннем порядке изменять стоимость услуг. Информация об актуальной стоимости услуг доступна Пользователю на Платформе-агрегаторе в соответствующем разделе. Изменение стоимости в отношении уже оплаченной Пользователем услуги не производится.</div>
            <div className={style.text}>2.3.4.Приостанавливать работу Платформы-агрегатора для проведения необходимых плановых профилактических и неплановых ремонтных работ.</div>
            <div className={style.text}>2.3.5.Приостановить доступ к Платформе-агрегатору или иному ресурсу, имеющему отношение к оказанию услуг по настоящему Договору, в случае нарушения Пользователем настоящего Договора и/или пользовательского соглашения, размещенного на Платформе- агрегаторе, а также в случаях непредоставления или предоставления некорректной информации, необходимой для оказания услуг по Договору.</div>
            <div className={style.text}><span style={{ fontWeight: '700' }}>2.4. Пользователь вправе:</span></div>
            <div className={style.text}>2.4.1.Получать у Агрегатора информационную поддержку по вопросам, связанным с порядком оказания услуг и работой Платформы-агрегатора.</div>
            <div className={style.text}>2.4.2.Потребовать возврат денежных средств, за минусом комиссий взымаемых сторонними платежными сервисами, в период пока Агрегатор еще не начал оказывать услугу.</div>
          </div>
          <div className={style.titlesThree}>3. Порядок предоставления услуг Агрегатором.</div>
          <div className={style.headingThree}>
            <div className={style.text}>3.1.Под предоставлением услуг, Стороны понимают предоставление доступа к Платформе- агрегатору или ресурсам Партнеров на персональных условиях для Пользователя.</div>
            <div className={style.text}>3.2.Содержание услуги может изменяться Агрегатором или Партнером в одностороннем порядке, путем увеличения или оптимизации количества предоставляемой информации в рамках оказания услуг по Договору.</div>
          </div>
          <div className={style.titlesFour}>4. Финансовые Положения Настоящего Договора.</div>
          <div className={style.headingFour}>
            <div className={style.text}>4.1.Услуга предоставляется Пользователю в порядке и по тарифу указанных на Платформе- агрегаторе, на условиях стопроцентной предоплаты.</div>
            <div className={style.text}>4.2.Если Агрегатор, предоставляет дополнительную скидку или другие бонусы Пользователю, то данные условия являются персональными и носят индивидуальный характер.</div>
            <div className={style.text}>4.3.Датой исполнения Пользователем обязательств по оплате услуг Агрегатора, является дата поступления денежных средств на расчетный счет Агрегатора.</div>
            <div className={style.text}>4.4.При оплате услуг на Платформе-агрегаторе Пользователь автоматически перенаправляется на страницу системы приема платежей для внесения оплаты. Агрегатор не контролирует аппаратно-программный комплекс электронной системы платежей. Если в результате таких ошибок произошло списание денежных средств Пользователя, но платеж не был авторизован электронной системой платежей, обязанности по возврату денежных средств Пользователю лежат на провайдере электронной системы платежей.</div>
            <div className={style.text}>4.5.Правила возврата денежных средств:</div>
            <div className={style.text}>4.5.1Возврат денежных средств Пользователю в соответствии с 782 ГК РФ (отказ от Договора) возможен до исполнения сторонами обязательств по Договору, т. е. до момента начала оказания услуги.</div>
            <div className={style.text}>4.5.2. В случае отказа Пользователя от Договора и наличии у него права на возврат денежных средств, Пользователь со своей электронной почты, указанной в Договоре направляет на 001@dels.pro в адрес Агрегатора заявление в свободной форме с указанием причины отказа от Договора. Возврат средств осуществляется в течении 10ти календарных дней с момента получения заявления от Пользователя, на тот же расчетный счет Пользователя с которого производилась оплата. В случаи наличия возражений со стороны Агрегатора, в течении десяти рабочих дней Агрегатор предоставляет свои возражения Пользователю в ответ на заявление. Комиссия, которую взымает платежный сервис в момент оплаты, возврату не подлежит.</div>
          </div>
          <div className={style.titlesFive}>5. Исключительные права.</div>
          <div className={style.headingFive}>
            <div className={style.text}>5.1.Исключительное право на Платформу-агрегатор, в том числе на ее части принадлежат Агрегатору.</div>
            <div className={style.text}>5.2.Пользователь имеет право пользоваться Платформой-Агрегатором в рамках предоставленного функционала и интерактивного взаимодействия с доступной информацией на все время доступа к Платформе-агрегатору в соответствии с настоящим Договором.</div>
            <div className={style.text}>5.3.Пользователь обязан:<br/>-воздерживаться от любых действий, которые нарушают права Агрегатора на результаты интеллектуальной деятельности, в частности, не копировать, не записывать, не воспроизводить, не распространять любые результаты интеллектуальной деятельности Агрегатора без письменного разрешения Агрегатора;<br />-немедленно сообщать Агрегатору о любых ставших известными фактах нарушения исключительных прав Агрегатора;<br />-не предоставлять свои аутентификационные данные для доступа на Платформу-агрегатор или сервисы Партнеров третьим лицам. В случае утраты, а также в случаях незаконного получения доступа к логину и паролю третьими лицами, Пользователь обязуется незамедлительно сообщить об этом Агрегатору До момента отправки указанного извещения все действия, совершенные с использованием личных данных Пользователя, считаются совершенными Пользователем.</div>
            <div className={style.text}>5.4.Использование Пользователем Платформы-агрегатора, ее содержимого и составляющих (как в целом, так и фрагментарно) и прочих разработанных Агрегаторомтехнических решений не означает передачи (отчуждения) Пользователю и / или любому третьему лицу прав на результаты интеллектуальной деятельности, как в целом, так и в части.</div>
          </div>
          <div className={style.titlesSix}>6. Конфиденциальность.</div>
          <div className={style.headingSix}>
            <div className={style.text}>6.1.К конфиденциальной информации относится любая информация, относящаяся к процессу оказания услуг Агрегатором неопубликованная в открытом доступе и не являющаяся доступной для всеобщего сведения. Пользователь обязуется не разглашать конфиденциальную информацию и иные данные, предоставленные Агрегатором в ходе оказания услуг (за исключением общедоступной информации), третьим лицам без предварительного письменного согласия Агрегатора.</div>
            <div className={style.text}>6.2.В рамках оказания услуг Пользователь может предоставляться доступ к программному обеспечению сторонних правообладателей, в том числе к внутренним информационным системам, сайтам (далее — ПО) через Платформу-агрегатор. В таком случае Заказчик не может совершать с ПО никаких действий за исключением тех, которые необходимы для получения услуги в рамках Договора.</div>
          </div>
          <div className={style.titlesSeven}>7. Ответственность Сторон.</div>
          <div className={style.headingSeven}>
            <div className={style.text}>7.1.Агрегатор несет ответственность в соответствии с законодательством РФ при наличии его вины.</div>
            <div className={style.text}>7.2.При неисполнении Пользователем обязательства, предусмотренного пунктами 6.1 и/или 6.3 Договора, и выявлении Агрегатором факта доступа третьих лиц к оплаченной услуге, Пользователь обязан во внесудебном порядке по письменному требованию Агрегатора оплатить штраф в размере 100000 (ста тысяч) рублей за каждый случай несанкционированного предоставления доступа третьим лицам.</div>
            <div className={style.text}>7.3.В случае нарушения Пользователем условий Договора и/или Пользовательского соглашения, Агрегатор вправе заблокировать доступ Пользователя к услуге, денежные средства в этом случаи, возврату не подлежат.</div>
            <div className={style.text}>7.4.Платформа-агрегатор и ее программные средства, предоставляются «Как есть». Агрегатор не несет ответственности за неисполнение или ненадлежащее исполнение обязательств по настоящему Договору, а также за возможный ущерб, возникший в результате:</div>
            <div className={style.text}>А) сбоев в работе Платформы-агрегатора и (или) иного программного обеспечения, вызванных ошибками в коде, компьютерными вирусами и иными посторонними фрагментами кода в программном обеспечении;</div>
            <div className={style.text}>Б) отсутствия (невозможности установления, прекращения и пр.) Интернет-соединений;</div>
            <div className={style.text}>В) установления государственного регулирования (или регулирования иными организациями) хозяйственной деятельности коммерческих организаций в сети Интернет и/или установления указанными субъектами разовых ограничений, затрудняющих или делающих невозможным исполнение настоящего Договора;</div>
            <div className={style.text}>Г) других случаев, связанных с действием (бездействием) пользователей Интернета и/или других субъектов, направленными на ухудшение общей ситуации с использованием сети Интернет и/или компьютерного оборудования;</div>
            <div className={style.text}>Д) использования (невозможность использования) и какие бы то ни было последствия использования (невозможности использования) Исполнителем выбранной им формы оплаты услуг по Договору.</div>
          </div>
          <div className={style.titlesEight} style={{ fontSize: '13px' }}>8. Срок Действия и Порядок Расторжения Настоящего Договора.</div>
          <div className={style.headingEight}>
            <div className={style.text}>8.1.Договор вступает в силу с даты его акцепта Пользователем и действует до оказания Агрегатором и/или Партнером оплаченной услуги Исполнителю.</div>
            <div className={style.text}>8.2.Договор может быть расторгнут в любое время по инициативе любой из Сторон путем направления другой Стороне соответствующего уведомления с обязательным указанием даты расторжения.</div>
          </div>
          <div className={style.titlesNine}>9. Изменение Условий Договора.</div>
          <div className={style.headingNine}>
            <div className={style.text}>9.1. Агрегатор вправе в одностороннем порядке изменять любые условия Договора, и такие изменения вступают в силу в момент опубликования новой версии Договора на Платформе- агрегаторе, при этом в части уже оплаченных услуг, старая версия Договора продолжает действовать для Сторон без изменений. В остальной части новые положения Договора имеют обратную силу.</div>
          </div>
          <div className={style.titlesTen}>10. Рассылки и Персональные Данные.</div>
          <div className={style.headingTen}>
            <div className={style.text}>10.1.Пользователь дает свое согласие Агрегатору на обработку персональных данных Пользователя.</div>
            <div className={style.text}>10.2.Пользователь дает согласие на получение от Агрегатора смс-рассылок, а также иных видов рассылок и уведомлений, информационного характера (устных и письменных), с использованием любых средств связи, включая, но не ограничиваясь следующими: электронная почта, телефон, почтовые рассылки. Данное согласие является встречным предоставлением Пользователя за возможность использования демонстрационной части Платформы-агрегатора. Настоящее согласие может быть в любое время отозвано Пользователем посредством направления уведомления по электронной почте Агрегатора. С учетом того, что данное согласие необходимо для корректного исполнения Договора со стороны Агрегатора и корректного функционирования Платформы-агрегатора, в случае отзыва согласия по настоящему пункту Агрегатор вправе расторгнуть Договор в одностороннем (внесудебном) порядке или ограничить доступ к Платформе-агрегатору.</div>
            <div className={style.text}>10.3.Пользователь дает согласие на использование Агрегатором самостоятельно, отзывов Пользователя об Агрегаторе и оказываемых им услугах.</div>
            <div className={style.text}>10.4.Пользователь дает свое согласие на получение рекламно-информационного материала от Партнеров в целях возможного получения различных поощрений (бонусы, скидки) от них. Условия получения и размер поощрения определяется Партнерами самостоятельно.</div>
          </div>
          <div className={style.titlesEleven}>11. Разрешение Споров.</div>
          <div className={style.headingEleven}>
            <div className={style.text}>11.1.В случае возникновения любых разногласий между Пользователем и Агрегатором относительно исполнения каждой из Сторон условий Договора, а также любых иных разногласий, такие разногласия должны быть урегулированы с применением обязательного досудебного претензионного порядка. Срок ответа на претензию - 10 (десять) рабочих дней со дня ее получения. После соблюдения претензионного порядка дело может быть передано в Арбитражный суд города Архангельска.</div>
          </div>
          <div className={style.titlesTwelv}>12. Иные Условия.</div>
          <div className={style.headingTwelv}>
            <div className={style.text}>12.1.Недействительность одного из условий Договора не влечет недействительности всего Договора в целом. В случае признания условия Договора недействительным, Стороны обязаны вступить в переговоры и изменить Договор таким образом, чтобы он продолжил свое действие.</div>
            <div className={style.text}>12.2.Во всем остальном, что не урегулировано Договором, Стороны руководствуются действующим законодательством Российской Федерации без учета его коллизионных норм.</div>
          </div>
          <div className={style.titlesThirteen}>13. Информация об Агрегаторе.</div>
          <div className={style.headingThirteen}>
            <div className={style.text}>ООО «КАКТУС» ИНН: 2901314285 КПП 290101001 ОГРН 1232900004424 Юридический адрес:</div>
            <div className={style.text}>163051, Архангельская область, г. Архангельск, ул. Гагарина д. 42, помещ. 52. Почтовый адрес:</div>
            <div className={style.text}>163051, Архангельская область, г. Архангельск, ул. Гагарина д. 42</div>
            <div className={style.text}>Р/с: 40702810910001464126 В АО «Т - Банк» К/с: 30101810145250000974 БИК: 044525974</div>
            <div className={style.text}>Генеральный директор: Федосеев В.С.</div>
          </div>
          <button type="button" className={style.btn} onClick={onClose}>Закрыть</button>
        </div>
      ) : modal_id == 'politic' ? (
        <div className={style.windowPolitic}>
          <div className={style.title}>СОГЛАШЕНИЕ ОБ ИСПОЛЬЗОВАНИИ И ОБРАБОТКЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</div>
          <div className={style.titlesOne}>1. ОБЩИЕ ПОЛОЖЕНИЯ</div>
          <div className={style.headingOne}>
            <div className={style.text}>Настоящее Соглашение об использовании сайта и обработке персональных данных разработано в соответствии с Политикой обработки персональных данных ООО «КАКТУС» (далее – «Оператор»), а также Федеральным законом от 27.07.2006 г. № 152-ФЗ «О персональных данных» (далее – ФЗ-152).</div>
            <div className={style.text}>Настоящее Соглашение действует в отношении информации, которую Оператор может получить о физических лицах – пользователях сайта (далее – «Пользователь») во время использования ими интернет-сайта Оператора – https://lastik.vip/ (далее – «Сайт»).</div>
            <div className={style.text}>Использование Сайта в любой форме, в том числе перемещение по сайту и изучение размещенных на нем материалов, заполнение форм сайта и предоставление информации о себе означает полное согласие Пользователя с условиями обработки его персональных данных, установленными настоящим Соглашением и Политикой обработки персональных данных ООО «КАКТУС» опубликованной на Сайте.</div>
            <div className={style.text}>Пользователь, заполняя анкетные формы, размещенные на Сайте, в том числе при нажатии кнопок: «Заказать услугу», «Заказать обратный звонок», «Регистрация», «Рассчитать стоимость услуги» а также заполняя иные формы, размещенные на сайте, содержание которых предполагает предоставление Пользователем Оператору персональных данных, дает свое согласие Оператору на обработку своих персональных данных. Под согласием в данном разделе настоящего Соглашения понимается согласие субъекта персональных данный на обработку персональных данных в понимании Федерального закона от 27.07.2006 г. № 152-ФЗ «О персональных данных».</div>
          </div>
          <div className={style.titlesTwo}>2. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</div>
          <div className={style.headingTwo}>
            <div className={style.text}>Оператор осуществляет обработку персональных данных Пользователей с целью продажи и продвижения товаров и услуг, в том числе:</div>
            <div className={style.text}>регистрации и авторизации Пользователя на сайте;</div>
            <div className={style.text}>персонализации Сайта и его предложений (как на самом Сайте, так и в последующей (в том числе, электронной) коммуникации с Пользователем) в соответствии с определяемыми (в том числе, автоматическими средствами обработки) интересами Пользователя к контенту Сайта;</div>
            <div className={style.text}>прием и обработка осуществляемых Пользователем на Сайте заявок;</div>
            <div className={style.text}>предоставление Пользователю информации справочного и рекламного характера о товарах/услугах и иных предложениях, реализуемых на Сайте, посредством направления сообщений по адресу электронной почты или телефону.</div>
            <div className={style.text}>предоставление Пользователю информации справочного и рекламного характера о товарах/услугах и иных предложениях, реализуемых на Сайте, посредством личного общения с Пользователем по предоставленному им номеру телефона;</div>
            <div className={style.text}>выполнение Оператором принятых на себя обязательств перед Пользователем;</div>
            <div className={style.text}>осуществления деятельности по продвижению товаров и услуг Оператора.</div>
          </div>
          <div className={style.titlesThree}>3. ОБЪЕМ ПЕРСОНАЛЬНЫХ ДАННЫХ</div>
          <div className={style.headingThree}>
            <div className={style.text}>В рамках настоящего Соглашения и Политики обработки персональных данных ООО «КАКТУС» Оператор может получить от Пользователей следующую информацию:</div>
            <div className={style.text}>фамилия, имя, отчество;</div>
            <div className={style.text}>телефон;</div>
            <div className={style.text}>адрес электронной почты (e-mail);</div>
            <div className={style.text}>адрес;</div>
            <div className={style.text}>место выполнения услуги;</div>
            <div className={style.text}>назначение объекта;</div>
            <div className={style.text}>Информация о составе услуги;</div>
            <div className={style.text}>иная информация, которую Пользователь предоставляет самостоятельно по собственному усмотрению путем заполнения соответствующих форм на Сайте.</div>
            <div className={style.text}>Дополнительно Оператору могут стать доступны иные данные Пользователя, которые автоматически передаются от Пользователя к Оператору при использовании Сайта, а именно: сведения о местоположении; IP-адрес, тип и версия ОС; тип и версия браузера; тип устройства и разрешение его экрана; язык ОС и браузера; информация, сохраненная в файлах «cookies».</div>
          </div>
          <div className={style.titlesFour}>4. Финансовые Положения Настоящего Договора.</div>
          <div className={style.headingFour}>
            <div className={style.text}>Пользователь вправе внести изменения в предоставленные им персональные данные, запросить их удаление/уничтожение/блокировку, а также отозвать свое согласие на обработку персональных данных, направив Оператору соответствующий запрос по адресу электронной почты: 001@dels.pro</div>
            <div className={style.text}>Оператор обязуется не передавать полученную от Пользователя информацию третьим лицам, за исключением случаев, предусмотренных Федеральным законом от 27.07.2006 г. № 152-ФЗ «О персональных данных» и условиями настоящего Соглашения.</div>
            <div className={style.text}>Оператор вправе использовать технологию "cookies" для улучшения использования Пользователем Сайта и для достижения указанных в п. 2 Соглашения целей, а также вправе получать и обрабатывать иную информацию о Пользователе и использовании им Сайта (включая информацию об IP-адресе Пользователя, информацию о переходе Пользователя на сайт и пр.).</div>
            <div className={style.text}>При обработке персональных данных Пользователя Оператор руководствуется положениями Федерального закона от 27.07.2006 г. № 152-ФЗ «О персональных данных» и принимаемых в соответствии с ним подзаконных актов, а также принимает необходимые организационные и технические меры для защиты персональных данных от неправомерного доступа к ним, а также от иных неправомерных действий в отношении персональных данных Пользователя. Оператор не несет ответственности за передачу Пользователем информации по незащищенным каналам связи и в иных случаях, находящихся вне разумного контроля со стороны Оператора.</div>
            <div className={style.text}>Пользователь обязан обеспечить полноту, точность и достоверность предоставляемой им информации, а также принять меры к тому, чтобы при предоставлении Пользователем информации не было нарушено применимое законодательство, а также законные права и интересы третьих лиц.</div>
            <div className={style.text}>Пользователь соглашается получать от Оператора информационные, новостные и рекламные сообщения, рассылки и прочую связанную с этим информацию в любой форме. При этом Пользователь вправе отказаться от получения указанной информации, путем направления Оператору соответствующего обращения.</div>
            <div className={style.text}>При обработке персональных данных Пользователя Оператор руководствуется положениями Федерального закона от 27.07.2006 г. № 152-ФЗ «О персональных данных» и принимаемых в соответствии с ним подзаконных актов, а также принимает необходимые организационные и технические меры для защиты персональных данных от неправомерного доступа к ним, а также от иных неправомерных действий в отношении персональных данных Пользователя. Оператор не несет ответственности за передачу Пользователем информации по незащищенным каналам связи и в иных случаях, находящихся вне разумного контроля со стороны Оператора.</div>
            <div className={style.text}>Сбор и хранение персональных данных, предоставляемых Пользователем, осуществляется исключительно в электронной форме (если иное не требуется согласно применимому законодательству).</div>
            <div className={style.text}>Фактом использования Пользователем сайта (в любой форме, включая указанные выше), Пользователь выражает свое согласие с настоящим Соглашением и всеми его условиями, а само осуществление Пользователем таких действий признается обеими сторонами конклюдентными действиями, направленными на заключение Пользователем настоящего Соглашения и принятие его условий, и считается соблюдением письменной формы Соглашения (п.3 ст.434 ГК РФ).</div>
          </div>
          <button type="button" className={style.btn} onClick={onClose}>Закрыть</button>
        </div>
      ) : null}
    </div>
  );
};
