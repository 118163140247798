import styled, {keyframes} from "styled-components";
import * as React from "react";
import {RootState} from "../redux/store";
import {useEffect, useState} from "react";
import {useAppSelector} from "../hooks/redux";

const hintSelector = (state: RootState) => state.loadingReducer.hint;

/**
 * @Composable
 * Компонент Загрузки
 * @constructor
 */
export function Loading() {
    const hint = useAppSelector(hintSelector)
    const [dots, setDots] = useState('...');

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (dots.length > 1) {
                setDots(dots.slice(0, -1));
            } else {
                setDots('...');
            }
        }, 700);

        return () => clearInterval(intervalId);
    }, [dots]);


    return (
        <ScLoading>
            <ScLoadingSpinner/>
            <span>{hint}{dots}</span>
        </ScLoading>
    )
}

const ScLoading = styled.div`
    display: flex;
    flex-direction: column;
    
    align-items: center;
    
    row-gap: 20px;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const ScLoadingSpinner = styled.div`
    animation: ${rotate360} 1s linear infinite;
    transform: translateZ(0);

    border-top: 2px solid grey;
    border-right: 2px solid grey;
    border-bottom: 2px solid grey;
    border-left: 4px solid black;
    background: transparent;
    width: 24px;
    height: 24px;
    border-radius: 50%;
`

export default Loading;