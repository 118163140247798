import styled from "styled-components";
import icon from "../../assets/select_arrow.svg";


/**
 * @Composable
 * Иконка стрелочки вниз для dropdown списка
 * @constructor
 */
export function SelectArrowIcon() : React.JSX.Element {
    return (
        <ScSelectArrowIcon src={icon} alt={"select_arrow"}/>
    )
}

const ScSelectArrowIcon = styled.img`
`

export default SelectArrowIcon;