import styled from "styled-components";
import icon from "../../assets/checkmark.svg";


/**
 * @Composable
 * Иконка Галочка
 * @constructor
 */
export function CheckmarkIcon() : React.JSX.Element{
    return (
        <ScCheckmarkIcon src={icon} alt={"checkmark"}/>
    )
}

const ScCheckmarkIcon = styled.img`
`

export default CheckmarkIcon;