import React from "react";

type LaborContextValue = {
    laborId: string
}

export const CurrentContext = React.createContext<LaborContextValue>(null)

export function useLaborContext() {
    const context = React.useContext(CurrentContext);
    if (!context) {
        throw new Error(`#701 Не обнаружен родительский LaborContextProvider`);
    }

    return context;
}

export default useLaborContext;