import {ClassicRadioButton} from "../components/ClassicRadioButton";
import {useState} from "react";
import styled, {keyframes, useTheme} from "styled-components";
import {useSnackbar} from "@mui/base";
import {apiClient} from "../api/auth";
import {FieldValues, useForm} from "react-hook-form";
import {Link} from "react-router-dom";
import * as React from "react";
import ScInput from "../components/styled/ScInput";
import ScPopupForm from "../components/styled/ScPopupForm";
import {ScFilledButton} from "../components/styled/ScButton";


const roles = [
    {
        "id" : "individual",
        "text" : "Физическое лицо"
    },
    {
        "id" : "corporate",
        "text" : "Юридическое лицо"
    },
    {
        "id" : "cleaner",
        "text" : "Клинер"
    }
]


/**
 * @Composable
 * Компонент страницы регистрации
 * @constructor
 */
export function SignUpPage() {
    const [notify, setNotify] = useState("");
    const { typography } = useTheme();

    const [selected_role_id, setSelectedRoleId] = useState(roles[0].id);
    const handleSelectChange = (event : React.ChangeEvent<HTMLInputElement>)=> {
        const value = event.target.value;

        setSelectedRoleId(value);
    };

    const {
        register,
        handleSubmit ,
        formState: { errors }
    } = useForm();

    const onFormValidationSubmit = (data: FieldValues) => {
        console.log(data);

        delete data["password_confirm"]

        apiClient.post('/signup/' + selected_role_id , data)
            .then(response => {
                if (response.status === 200) {
                    handleOpen("Вы успешно зарегистрированы")
                }
            })
            .catch(() => {
                handleOpen("Email занят")
            })
    }
    const onFormValidationError = (error: FieldValues) => {
        console.log(error)
    }

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const { getRootProps } = useSnackbar({
        onClose: handleClose,
        open,
        autoHideDuration: 5000,
    });

    const handleOpen = (text: string) => {
        setNotify(text);
        setOpen(true);
    };

    return (
        <ScSignUpPage>
            <ScPopupForm>
                <div className={'role-row'}>
                    {
                        roles.map(role => {
                            return (
                                <ClassicRadioButton
                                    value={role.id.toString()}
                                    checked={selected_role_id === role.id}
                                    onChange={event => handleSelectChange(event)}
                                    text={role.text}
                                />
                            )
                        })
                    }
                </div>

                <form className={'inputs-column'}>
                    {selected_role_id === "corporate" && (
                        <>
                            <ScInput
                                {...register("company_name", {
                                    required: {
                                        value: true,
                                        message: "Заполните поле"
                                    },
                                    minLength: {
                                        value: 2,
                                        message: "Заполните поле"
                                    },
                                    maxLength: {
                                        value: 100,
                                        message: "Поле не должно превышать 100 символов"
                                    },
                                })}
                                placeholder={"Название организации"}

                                aria-invalid={errors["company_name"] ? 'true' : 'false'}
                            />
                            <span className={"error-text"}>
                                {errors["company_name"] ? errors["company_name"].message.toString() : ""}
                            </span>
                        </>
                    )}

                    {selected_role_id === "corporate" && (
                        <>
                            <ScInput
                                {...register("company_tid", {
                                    required: {
                                        value: true,
                                        message: "Заполните поле"
                                    },
                                    pattern: {
                                        value: /^\d{10}$/,
                                        message: "Введите корректный ИНН"
                                    },
                                    maxLength: {
                                        value: 10,
                                        message: "Поле не должно превышать 10 символов"
                                    },
                                })}
                                placeholder={"ИНН организации"}

                                aria-invalid={errors["company_tid"] ? 'true' : 'false'}
                            />
                            <span className={"error-text"}>
                                {errors["company_tid"] ? errors["company_tid"].message.toString() : ""}
                            </span>
                        </>
                    )}

                    <ScInput
                        {...register("surname", {
                            required: {
                                value: true,
                                message: "Заполните поле"
                            },
                            minLength: {
                                value: 2,
                                message: "Заполните поле"
                            },
                            maxLength: {
                                value: 50,
                                message: "Поле не должно превышать 50 символов"
                            },
                        })}
                        placeholder={"Фамилия"}

                        aria-invalid={errors["surname"] ? 'true' : 'false'}
                    />
                    <span className={"error-text"}>
                        {errors["surname"] ? errors["surname"].message.toString() : ""}
                    </span>

                    <ScInput
                        {...register("name", {
                            required: {
                                value: true,
                                message: "Заполните поле"
                            },
                            minLength: {
                                value: 2,
                                message: "Заполните поле"
                            },
                            maxLength: {
                                value: 50,
                                message: "Поле не должно превышать 50 символов"
                            },
                        })}
                        placeholder={"Имя"}

                        aria-invalid={errors["name"] ? 'true' : 'false'}
                    />
                    <span className={"error-text"}>
                        {errors["name"] ? errors["name"].message.toString() : ""}
                    </span>

                    <ScInput
                        {...register("patronymic", {
                            required: {
                                value: false,
                                message: "Заполните поле"
                            },
                            minLength: {
                                value: 2,
                                message: "Поле должно быть более 2 символов"
                            },
                            maxLength: {
                                value: 50,
                                message: "Поле не должно превышать 50 символов"
                            }
                        })}
                        placeholder={"Отчество"}

                        aria-invalid={errors["patronymic"] ? 'true' : 'false'}
                    />
                    <span className={"error-text"}>
                        {errors["patronymic"] ? errors["patronymic"].message.toString() : ""}
                    </span>

                    <ScInput
                        {...register("email", {
                            required: {
                                value: true,
                                message: "Заполните поле"
                            },
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Введите корректный email"
                            }
                        })}
                        placeholder={"Email"}

                        aria-invalid={errors["email"] ? 'true' : 'false'}
                    />
                    <span className={"error-text"}>
                        {errors["email"] ? errors["email"].message.toString() : ""}
                    </span>

                    {selected_role_id === "corporate" && (
                        <>
                            <ScInput
                                {...register("rank", {
                                    required: {
                                        value: true,
                                        message: "Заполните поле"
                                    },
                                    minLength: {
                                        value: 2,
                                        message: "Заполните поле"
                                    },
                                    maxLength: {
                                        value: 50,
                                        message: "Поле не должно превышать 50 символов"
                                    },
                                })}
                                placeholder={"Должность"}

                                aria-invalid={errors["rank"] ? 'true' : 'false'}
                            />
                            <span className={"error-text"}>
                                {errors["rank"] ? errors["rank"].message.toString() : ""}
                            </span>
                        </>
                    )}

                    <ScInput
                        {...register("password", {
                            required: {
                                value: true,
                                message: "Заполните поле"
                            },
                            minLength: {
                                value: 6,
                                message: "Пароль должен составлять не менее 6 символов"
                            },
                            validate: {
                                uppercase: v => {
                                    if (!v.match(/[A-Z]/))
                                        return "Пароль должен содержать заглавные буквы"
                                },
                                lowercase: v => {
                                    if (!v.match(/[a-z]/))
                                        return "Пароль должен содержать строчные буквы"
                                },
                                number: v => {
                                    if (!v.match(/\d/))
                                        return "Пароль должен содержать цифры"
                                }
                            },
                            maxLength: {
                                value: 100,
                                message: "Пароль не должен превышать 100 символов"
                            },
                        })}
                        placeholder={"Пароль"}
                        type={"password"}

                        aria-invalid={errors["password"] ? 'true' : 'false'}
                    />
                    <span className={"error-text"}>
                        {errors["password"] ? errors["password"].message.toString() : ""}
                    </span>

                    <ScInput
                        {...register("password_confirm", {
                            required: {
                                value: true,
                                message: "Заполните поле"
                            },
                            validate: {
                                match: (value, formValues) => {
                                    if (value !== formValues["password"])
                                        return "Пароли не совпадают"
                                }
                            }
                        })}
                        placeholder={"Повторите пароль"}
                        type={"password"}

                        aria-invalid={errors["password_confirm"] ? 'true' : 'false'}
                    />
                    <span className={"error-text"}>
                        {errors["password_confirm"] ? errors["password_confirm"].message.toString() : ""}
                    </span>
                </form>

                <ScFilledButton fontSize={typography.fontSizes.large} style={{padding: '0.5em'}} onClick={handleSubmit(onFormValidationSubmit, onFormValidationError)}>Регистрация</ScFilledButton>

                <div className={"lowergrid"}>
                    <span>Уже зарегистрированы?</span>
                    <span><Link to="/signin">Войти</Link></span>
                </div>
            </ScPopupForm>
            {open && <CustomSnackbar {...getRootProps()}>{notify}</CustomSnackbar>}
        </ScSignUpPage>
    )
}

const ScSignUpPage = styled.div`
    display: grid;
    align-items: center;
    justify-items: center;
    
    width: 100%;
    height: 100%;
    
    .role-row {
        display: flex;
        justify-content: space-around;
        column-gap: 10px;
        font-size: ${props => props.theme.typography.fontSizes.small};
        color: ${props => props.theme.colors.secondary};
    }
    
    .inputs-column {
        display: flex;
        flex-direction: column;
        
        min-width: 300px;
        
        align-self: center;

        row-gap: 2px;
    }
    
    ${ScPopupForm} > ${ScFilledButton} {
        margin-top: 7px;
    }

    div.lowergrid {
        display: flex;
        justify-content: space-between;
        color: ${props => props.theme.colors.secondary};
        font-size: ${props => props.theme.typography.fontSizes.small};

        > *:last-child {
            text-decoration: underline;
            font-weight: 400;
        }
    }

    .error-text {
        color: red;
        font-size: 0.8em;
        min-height: 0.8em;
    }
`

const snackbarInRight = keyframes`
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
`;

const CustomSnackbar = styled.div`
    position: fixed;
    z-index: 5500;
    display: flex;
    right: 16px;
    bottom: 16px;
    left: auto;
    justify-content: space-between;
    max-width: 560px;
    min-width: 300px;
    background-color: ${props => props.theme.colors.secondaryContainer};
    border-radius: 8px;
    border: 1px solid ${props => props.theme.colors.secondary};

    padding: 0.75rem;
    color: ${props => props.theme.colors.tertiary};
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    animation: ${snackbarInRight} 200ms;
    transition: transform 0.2s ease-out;
`

export default SignUpPage;