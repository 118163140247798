import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {
    selectCalculatorMaxAvailablePointsValue, selectLaborById,
} from "../../../redux/reducers/calculator/calculatorSelectors";
import {updateLabor} from "../../../redux/reducers/calculator/calculatorSlice";
import React, {useEffect} from "react";
import ScInput from "../../../components/styled/ScInput";
import {ScTextButton} from "../../../components/styled/ScButton";
import useLaborContext from "../../../api/laborcontext/LaborContext";


/**
 * @Composable
 * Виджет использования бонусных баллов калькулятора
 * @constructor
 */
function CalculatorUsePointsWidget() : React.JSX.Element {
    const dispatch = useAppDispatch();

    const { laborId } = useLaborContext();

    const usedPointsValue = useAppSelector(selectLaborById(laborId)).usedPoints;
    const maxAvailablePoints = useAppSelector(selectCalculatorMaxAvailablePointsValue(laborId));

    /**
     * Функция устанавливающая значение баллов использования с учётом ограничений
     * @param points баллы для использования в заказе
     */
    const setPointsWithLimits = (points: number) => {
        dispatch(updateLabor({ id: laborId,  usedPoints: Math.min(maxAvailablePoints, points) }));
    }

    useEffect(() => {
        setPointsWithLimits(usedPointsValue)
    }, [maxAvailablePoints])

    return (<>
        <span className="calc__row--header">Использование баллов (максимум на этот заказ <ScTextButton onClick={() =>
            setPointsWithLimits(maxAvailablePoints)
        }>{maxAvailablePoints} баллов</ScTextButton>)</span>
        <ScInput type="number" min="0" max={maxAvailablePoints} value={usedPointsValue} onChange={(e) => setPointsWithLimits(Number(e.target.value))}/>
    </>)
}

export default CalculatorUsePointsWidget;