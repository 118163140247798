import styled from "styled-components";
import * as React from "react";

interface RadioButtonProps {
    // eslint-disable-next-line react-redux/no-unused-prop-types
    value: string
    selected: boolean
    // eslint-disable-next-line react-redux/no-unused-prop-types
    onClick?: (event: React.MouseEvent<HTMLElement>) => void
    className?: string
}

/**
 * Компонент input.radio обёрнутый в стиль кнопки для выбора значения
 * @param props
 * @constructor
 */
export function RadioButton(props: React.PropsWithChildren<RadioButtonProps>) {
    const classes : string[] = [];
    if (props.className) {
        classes.push(props.className)
    }

    if (props.selected) {
        classes.push("base--selected")
    }

    const { className, children, ...other } = props;

    return (
        <ScRadioButton {...other} className={classes.join(" ")} >
            {children}
        </ScRadioButton>
    );
}

const ScRadioButton = styled.button`
    text-align: center;
    
    min-height: 3em;

    border: 1px solid ${props => props.theme.colors.secondary}aa;
    border-radius: ${props => props.theme.rounded.normal};

    background-color: ${props => props.theme.colors.surface};
    color: ${props => props.theme.colors.secondary};
    
    &.base--selected {
        background-color: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.surface};
        border: 1px solid ${props => props.theme.colors.primary};
    }
`;