import axios, {InternalAxiosRequestConfig} from "axios";
import {BaseUserCredentialsModel} from "./users";
import Cookies from "universal-cookie";
import { jwtDecode } from 'jwt-decode'

const ACCESS_TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";
const API_HOST = "https://402.m-dels.ru";
// const API_HOST = "http://127.0.0.1:8000";

const REFRESH_TOKEN_PATH = "/oauth/refresh"
const ACCESS_TOKEN_PATH = "/oauth/token"

export const apiClient = axios.create({
    baseURL: API_HOST
});

/*
== Работа с хранилищем на клиенте
Абстракция для унифицированной работы с хранилищем вне зависимости от реализации
*/
const cookies = new Cookies(null, { path: '/' });

function _pushAccessToken(token: string) {
    cookies.set(ACCESS_TOKEN_KEY, token, { expires: _getTokenExpTime(token)})
}

function _pushRefreshToken(token: string) {
    cookies.set(REFRESH_TOKEN_KEY, token, { expires: _getTokenExpTime(token)})
}

function _pullAccessToken(): string | undefined {
    return cookies.get(ACCESS_TOKEN_KEY);
}

function _pullRefreshToken(): string | undefined {
    return cookies.get(REFRESH_TOKEN_KEY);
}

apiClient.interceptors.request.use(axios_onRequestFulfilled, axios_onRequestRejected);

/*
== Секция Событий
*/
/**
 * Событие обработки успешного запроса
 * @param config
 */
async function axios_onRequestFulfilled(config: InternalAxiosRequestConfig) {
    const accessToken = _pullAccessToken();
    if (accessToken) {
        // если существует актуальный access, установить
        config.headers["Authorization"] = `Bearer ${accessToken}`;
    } else if (await reSignInWithRefreshToken()) {
        // если автоавторизация прошла, то установить новый access
        config.headers["Authorization"] = `Bearer ${_pullAccessToken()}`;
    }

    return config;
}

/**
 * Событие обработки неуспешного запроса
 * @param error
 */
function axios_onRequestRejected(error: any) {
    return Promise.reject(error);
}


/*
== Секция Внутренней логики
*/
/**
 * Проверка времени жизни токена
 * @param token JWT строка без 'Bearer '
 * @throws Error если строка не является JWT
 * @returns true если время жизни токена истекло, иначе false
 */
function _isTokenExpired(token: string) {
    return _getTokenExpTime(token) < new Date();
}


/**
 * Получает момент истекания токена
 * @param token
 * @throws Error если строка не является JWT
 * @return момент истекания токена
 */
function _getTokenExpTime(token: string) : Date {
    return new Date(jwtDecode(token).exp * 1000)
}


/**
 * Получение access, если возможно
 * @param fullpath полный url включая домен
 * @returns access если он успешно получен, иначе undefined
 */
async function _refreshAccessTokenByPath(fullpath: string): Promise<string | undefined> {
    const refreshToken = _pullRefreshToken();
    if (!refreshToken) {
        return undefined;
    }

    try {
        // (!) здесь используется дефолтный axios-клиент без interceptor'ов для избежания цикла
        const response = await axios.post(fullpath,{}, {
            headers: {
                "Authorization" : `Bearer ${refreshToken}`
            }
        })

        if (response.status === 200) {
            return response.data.access_token;
        }
    }
    catch (e) {
        return undefined;
    }
    return undefined;
}


/*
== Секция Внешних функций
*/
/**
 * Попытка автоматической авторизации с помощью refresh токена
 * @returns true если авторизация удалась, иначе false
 */
async function reSignInWithRefreshToken(): Promise<boolean> {
    const newAccessToken = await _refreshAccessTokenByPath(API_HOST+ REFRESH_TOKEN_PATH)
    if (newAccessToken) {
        _pushAccessToken(newAccessToken);
        return true;
    }
    return false;
}


/**
 * Авторизация, получение JWT credentials
 * @param model модель авторизации
 * @returns true если авторизация успешна, иначе false
 */
async function signIn(model: BaseUserCredentialsModel): Promise<boolean> {
    try {
        const response = await apiClient.post(ACCESS_TOKEN_PATH, new URLSearchParams({...model}))
        _pushAccessToken(response.data.access_token);
        _pushRefreshToken(response.data.refresh_token);

        return response.status === 200;
    } catch(e) {
        return false
    }
}


/**
 * Удаление JWT токенов из хранилища
 */
function signOut(): void {
    cookies.remove(ACCESS_TOKEN_KEY);
    cookies.remove(REFRESH_TOKEN_KEY);
}


/**
 * Удостоверение, что пользователь авторизован. Если отсутствует access токен, то пытается автоавторизовать
 * @returns true если пользователя удалось автоавторизовать, иначе false
 */
async function ensureSignedIn(): Promise<boolean> {
    return _pullAccessToken() !== undefined || await reSignInWithRefreshToken();
}


export default { signIn, signOut, ensureSignedIn, reSignInWithRefreshToken, apiClient }