import padAsNumberZeros from "./padAsNumberZeros";

const dateToUTCString = (date: Date) : string => {
    return date.getFullYear() + '-'
        + padAsNumberZeros(date.getMonth() + 1) + '-'
        + padAsNumberZeros(date.getDate()) + 'T'
        + padAsNumberZeros(date.getHours()) + ':'
        + padAsNumberZeros(date.getMinutes());
}

export default dateToUTCString;