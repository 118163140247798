import { useEffect, useState } from 'react';
import Axios from 'axios'
import { useForm } from 'react-hook-form';
import { SpisokModal } from './SpisokModal';
import { ReactComponent as Check } from '../../../images/checkmark.svg';
import { ReactComponent as Wapp } from '../../../images/Wapp.svg';
import { ReactComponent as Tg } from '../../../images/Tgs.svg';
import style from './PhoneModal.module.scss';

// типизация пропсов
type TModalProps = {
  open: boolean;
  onClose: () => void;
  cleaner: boolean;
};

// типизация данных для отправки с формы
type TFormValues = {
  phone: string;
  name: string;
  type: string;
};

/**
 * @function {PhoneModal} - Модальное окно для заказа клинера по телефону / стать клинером.
 * @returns {React.JSX.Element} - JSX-элемент, представляющий отображение модального окна.
 */

export const PhoneModal = ({ open, onClose, cleaner }: TModalProps): React.JSX.Element => {
  const { register, handleSubmit, watch, setValue } = useForm<TFormValues>({ mode: 'onChange' });
  const [check, setCheck] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [errorCheck, setErrorCheck] = useState(false);
  const [send, setOpenSend] = useState(false);
  const [mask, setMask] = useState("+7(9__)___-__-__");
  const [cursorPosition, setCursorPosition] = useState(5.2);
  const [label, setLabel] = useState(false);
  const [openSpisok, setOpenSpisok] = useState(false);
  const [openSpisokId, setOpenSpisokId] = useState('');

  const nameLength = watch('name');
  const phoneLength = watch('phone');

  const api = `https://402.m-dels.ru/order`;

  // функция для ввода телефона
  const handlePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    let result = event.target.value.replace(/\D/g, '');
    if (result.length > 0) {
      setMask(`+7(9${result[0]}_)___-__-__`);
      setCursorPosition(6);
    }
    if (result.length > 1) {
      setMask(`+7(9${result.slice(0, 2)})___-__-__`);
      setCursorPosition(7);
    }
    if (result.length > 2) {
      setMask(`+7(9${result.slice(0, 2)})${result[2]}__-__-__`);
      setCursorPosition(8.5);
    }
    if (result.length > 3) {
      setMask(`+7(9${result.slice(0, 2)})${result.slice(2, 4)}_-__-__`);
      setCursorPosition(9.5);
    }
    if (result.length > 4) {
      setMask(`+7(9${result.slice(0, 2)})${result.slice(2, 5)}-__-__`);
      setCursorPosition(10.5);
    }
    if (result.length > 5) {
      setMask(`+7(9${result.slice(0, 2)})${result.slice(2, 5)}-${result[5]}_-__`);
      setCursorPosition(11.5);
    }
    if (result.length > 6) {
      setMask(`+7(9${result.slice(0, 2)})${result.slice(2, 5)}-${result.slice(5, 7)}-__`);
      setCursorPosition(12.5);
    }
    if (result.length > 7) {
      setMask(`+7(9${result.slice(0, 2)})${result.slice(2, 5)}-${result.slice(5, 7)}-${result[6]}_`);
      setCursorPosition(13.7);
    }
    if (result.length > 8) {
      setMask(`+7(9${result.slice(0, 2)})${result.slice(2, 5)}-${result.slice(5, 7)}-${result.slice(7, 9)}`);
      setCursorPosition(14.7);
    }
    if (result.length === 0) {
      setMask(`+7(9__)___-__-__`);
      setCursorPosition(5.2);
    }

    event.target.value = result.slice(0, 9);
    
    setValue('phone', result.slice(0, 9));
  };

  // функция для вкл/выкл принятия пользовательского соглашения
  const handleCheck = () => {
    setCheck(!check);
  };

  // функция для перехода в Whats App
  const handleWa = () => {
    const url = "https://api.whatsapp.com/message/232ZXS3OASO5P1?autoload=1&app_absent=0";
    window.open(url, '_blank'); 
  };


  // функция для перехода в Telegram
  const handleTg = () => {
    const url = "https://t.me/LaStik444";
    window.open(url, '_blank'); 
  };


  // закрываем модалку, скидываем параметры
  const handleClose = () => {
    onClose();
    setCheck(false);
    setErrorName(false);
    setErrorPhone(false);
    setErrorCheck(false);
    setLabel(false);
  };

  // функция для закрытия оферты/соглашения
  const handleSpisokClose = () => {
    setOpenSpisok(false);
    setOpenSpisokId('');
  }

  // Отправка формы
  const onSubmit = async (formData: TFormValues) => {
    if (!watch('name')) {
      setErrorName(true);
      return null;
    } else if(!watch('phone') || watch('phone').length !== 9) {
      setErrorPhone(true);
      return null;
    } else if(!check) {
      setErrorCheck(true);
      return null;
    } else {
      setErrorName(false);
      setErrorPhone(false);
      setErrorCheck(false);
      
      try {
        const dataToSend = {
          'order-info': {
            phone: formData.phone = mask,
            name: formData.name,
            type: formData.type || '',
          }
        };
        await Axios.post(api, dataToSend)
          .then((response) => {
            console.log(response);
          });
        setOpenSend(true);
      } catch (error) {
        console.log('Error:', error);
      }
    }
  };

  // скрываем скролл из body 
  useEffect(() => {
    if(open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    }
  },[open]);


  // при успешной отправке заявки показываем на 2 секунды поле и закрываем модалку
  useEffect(() => {
    if(send) {
      const times = setTimeout(() => {
        setOpenSend(false);
        onClose();
      }, 2000);

      return () => clearTimeout(times);
    }
  }, [send, onClose]);

  if (!open) return null;
  return (
    <form className={style.main} onClick={handleClose} onSubmit={handleSubmit(onSubmit)}>
      {send ? (
        <div className={style.window}>
          <div className={style.send}>Ваше обращение принято!</div>
        </div>
      ) : (
        <div 
          className={style.window}
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            setLabel(false);
          }}
        >
          <input 
            className={`${style.name} ${nameLength ? '' : style.notActive} ${errorName ? style.error : ''}`} 
            type="text" placeholder="Ваше имя" 
            {...register('name')} 
          />
          <label 
            className={`${style.phone} ${phoneLength ? '' : style.notActive} ${errorPhone ? style.error : ''}`}
            htmlFor="phone" 
            onClick={(e: React.MouseEvent<HTMLLabelElement>) =>{
              e.stopPropagation();
              setLabel(true);
            }}
          >
            {mask}
            <input
              id="phones"
              className={style.phones}
              type="text"
              {...register('phone', {onChange: handlePhone})}
            />
            <span className={label ? style.cursor : style.cursorHidden} style={{ left: `${cursorPosition * 10}px` }} />
          </label>
          <button type="button" className={style.check} onClick={handleCheck}>
            {check? <Check className={style.checks} /> : null}
          </button>
          <span className={errorCheck ? style.errorCheck : style.errorHidden}>Примите соглашение</span>
          <div className={style.contract}>
            <span>Принимаю <span className={style.link} onClick={() => {setOpenSpisok(true); setOpenSpisokId('offer')}}>пользовательское соглашение</span>. Соглашаюсь на <span className={style.link} onClick={() => {setOpenSpisok(true); setOpenSpisokId('politic')}}>обработку персональных данных</span>.</span>
          </div>
          <button type="submit" className={style.btn}>{cleaner ? 'Подать завку' : 'Перезвоните мне'}</button>
          <button type="button" className={style.btnWapp} onClick={handleWa}>
            <Wapp className={style.wapp} />
          </button>
          <button type="button" className={style.btnTg} onClick={handleTg}>
            <Tg className={style.tg} />
          </button>
        </div>
      )};
      <SpisokModal 
        open={openSpisok}
        modal_id={openSpisokId}
        onClose={handleSpisokClose} 
      />
    </form>
  );
};
