import {RootState} from "../../store";
import {createSelector} from "@reduxjs/toolkit";

export const selectWalletReducer = (state: RootState) => state.walletReducer;

/**
 * @Selector
 * Выбрать остаток баланса в рублях
 */
export const selectBalanceValue = createSelector(
    [selectWalletReducer],
    walletReducer => walletReducer.balanceValue
)


/**
 * @Selector
 * Выбрать количество бонусных баллов
 */
export const selectPointsValue = createSelector(
    [selectWalletReducer],
    walletReducer => walletReducer.pointsValue
)
