import withLoadable from "./utils/withLoadable";
import {Route, Routes} from "react-router-dom";
import EnsureAuthorized from "./utils/EnsureAuthorized";
import {lazy, ReactElement} from "react";
import useAuthContext from "./api/authcontext/AuthContext";
import EnsureLoaded from "./utils/EnsureLoaded";
import {useQuery} from "react-query";

const CabinetLayout = lazy(() => import("./layouts/CabinetLayout"));


/**
 * @Composable
 * Роутинговый компонент для удостоверения авторизации
 * @constructor
 */
function AuthorizedApp() : ReactElement {
    const authCtx = useAuthContext()


    const { isLoading } = useQuery(
        ['user'],
        async () => {
            return await authCtx.ensureUser()
        },
        {
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        }
    )

    return (
        <Routes>
            <Route element={<EnsureLoaded isLoading={isLoading}/>}>
                <Route element={<EnsureAuthorized/>}>
                    <Route path="/" element={withLoadable(<CabinetLayout/>)}/>
                </Route>
            </Route>
        </Routes>
    )
}

export default AuthorizedApp;