import { configureStore, combineReducers } from '@reduxjs/toolkit';
import loadingReducer from './reducers/loading';

import flagReducer from './reducers/mainPage/flagSlice';
import calculatorReducer from './reducers/calculator/calculatorSlice';
import walletReducer from './reducers/wallet/walletSlice';
import zulipReducer from './reducers/zulip/zulipSlice';
import popupReducer from "./reducers/popup/popupSlice";

const multipleReducer = combineReducers({
  loadingReducer,
  flagReducer,
  calculatorReducer,
  walletReducer,
  zulipReducer,
  popupReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: multipleReducer,
  });
};

export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>;
const store = setupStore();

export default store;
