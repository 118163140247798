import styled from "styled-components";
import {SignUpPage} from "./pages/SignUpPage";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import {SignInPage} from "./pages/SignInPage";
import AuthorizedApp from "./AuthorizedApp";
import {ReactElement} from "react";
import Main from "./pages/main/Main";

/**
 * @Composable
 * Главный файл React приложения
 * @returns {ReactElement}
 * @constructor
 */
function App() : ReactElement {
    return (
        <ScApp>
            <Router>
                <Routes>
                    <Route path="/signup" element={<SignUpPage/>}/>
                    <Route path="/signin" element={<SignInPage/>}/>
                    <Route path="/cabinet/*" element={<AuthorizedApp/>}/>
                    <Route path="/" element={<Main />}/>
                </Routes>
            </Router>
        </ScApp>
    );
}

export default App;

const ScApp = styled.div`
    display: grid;
    align-items: center;
    justify-items: center;
    min-height: 100vh;
`