import styled from "styled-components";


/**
 * @Composable
 * Всплывающая форма
 * @extends div
 */
const ScPopupForm = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: stretch;
    
    background: ${props => props.theme.colors.surface};
    border-radius: ${props => props.theme.rounded.normal};
    
    padding: 20px;
`

export default ScPopupForm;